import store, {loggedIn} from 'store';
export const subscribe = store.subscribe;

export function getRegStatus() {
  return _.get(store.getState(), 'user.regStatus');
}

export function getLoginInfo() {
  return _.get(store.getState(), 'user.loginInfo');
}

export function getInfo() {
  return _.get(store.getState(), 'user.userInfo');
}

export function getResetToken() {
  return _.get(store.getState(), 'user.resetToken');
}

export function getErrors() {
  return _.get(store.getState(), 'user.userStoreErrors');
}

export function getEmail() {
  return _.get(store.getState(), 'user.userInfo.email');
}

export function getDestroySuccess() {
  return _.get(store.getState(), 'user.destroySuccessful');
}

export function getDestroyRequestSuccess() {
  return _.get(store.getState(), 'user.destroyRequestSuccessful');
}

export default {
  subscribe,
  getRegStatus,
  getLoginInfo,
  getInfo,
  getResetToken,
  getErrors,
  loggedIn,
  getEmail,
  getDestroySuccess,
  getDestroyRequestSuccess,
  getState: store.getState
};
