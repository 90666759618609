import { Link, hashHistory, withRouter } from 'react-router';
import { IonItemCheckBox, IonSpinner, IonTabs } from 'reactionic';
import Geocode from 'react-geocode';

import ProjectStore from 'stores/ProjectStore';
import UserStore from 'stores/UserStore';
import ProjectActions from 'actions/ProjectActions';
import upgradeOldProjects from 'helpers/project-settings-upgrade';

import { withOnline } from 'components/helpers/online-helper';
import preventDefault from 'components/helpers/event';
import FormRow from 'components/helpers/form-row';
import countryOptions from 'components/helpers/country-options';
import CoordinateSelection from 'components/project/CoordinateSelection';

const API_KEY = process.env.LEGACY_GOOGLE_API_KEY || process.env.GOOGLE_API_KEY;
Geocode.setApiKey(API_KEY);

function geocodeAddress(address) {
  return Geocode.fromAddress(address).then(
    (response) => response.results[0].geometry.location,
    (error) => ({})
  );
}

function copiedName(name) {
  let newName = name + ' copy';
  const projectNames = _.map(ProjectStore.getProjects(), 'name');
  while (_.includes(projectNames, newName)) {
    const newVersion = parseInt(_.get(newName.match(/\d+$/), '0', 1), 10) + 1;
    newName = newName.replace(/ \d+$/, '') + ' ' + newVersion;
  }
  return newName;
}

const ADDRESS_FIELDS = ["address", "city", "state", "zipcode", "country"];
const PROJECT_FIELDS = ["name", "client", ...ADDRESS_FIELDS, "latitude", "longitude"];

export class ProjectForm extends React.Component {
  constructor(props) {
    super(props);
    const {project={}, route: {path=''}={}} = props;
    const {name="", zipcode, longitude, latitude, published=false, verified} = project || {};
    const {error, errorMsg} = ProjectStore.getProjectStatus();
    const existingNoAddress = !zipcode && longitude;
    this.state = {
      error, errorMsg,
      copyMode: !!path.match(/copy/),
      submitted: false,
      activeTab: existingNoAddress ? "coordinates" : "address",
      mapKey: 'initial',
      dirtyLocation: latitude && longitude,
      dirty: false,
      name: path.match(/copy/) ? copiedName(name) : name,
      client: project.client || "",
      address: project.address || "",
      city: project.city || "",
      state: project.state || "",
      zipcode: project.zipcode || "",
      country: project.country || "USA",
      longitude: project.longitude,
      latitude: project.latitude,
      isPrivate: !published,
      verified: _.isUndefined(verified) ? true : verified
    };
  }

  componentDidMount() {
    this.unsubscribeProjectStore = ProjectStore.subscribe(this._onChange);
    if (_.get(this.props, 'project.owned')) {
      upgradeOldProjects(_.get(this.props, 'project.settings.pile'), _.get(this.props, 'project.data.pileSegments'));
    }
    this.props.router.setRouteLeaveHook(this.props.route, () => {
      if (this.state.dirty && !this.state.submitted) {
          return "You have unsaved changes! Do you want to continue without saving?";
        }
      }
    );
  }

  componentWillUnmount() {
    this.unsubscribeProjectStore();
    ProjectActions.clearErrors();
  }

  _onChange = () => {
    const {lastUpdateSuccess=false, error, errorMsg, projectID} = ProjectStore.getProjectStatus();
    if (this.state.submitted && lastUpdateSuccess && projectID) {
      hashHistory.push(`/projects/${projectID}/loads`);
    } else if (this.state.submitted && error) {
      this.setState({error, errorMsg, submitted: false});
    } else if (error) {
      this.setState({error, errorMsg});
    }
  }

  set = (property, value) => {
    const isLocation = _.includes(['latitude', 'longitude'], property);
    return this.setState({[property]: value, dirty: true, dirtyLocation: isLocation});
  }

  selectTab = (tabname) => preventDefault(() => {
    const fullAddress = _.join(_.compact(_.values(_.pick(this.state, ADDRESS_FIELDS))), ", ");
    const userProvidedLocation = (this.state.longitude && this.state.latitude) || this.state.dirtyLocation;
    const addressPresent = fullAddress.length > 2;
    if (tabname === 'coordinates' && addressPresent && !userProvidedLocation) {
      geocodeAddress(fullAddress).then(
        ({lat, lng}) => this.setState({
          latitude: _.round(lat, 8),
          longitude: _.round(lng, 8),
          activeTab: tabname,
          mapKey: fullAddress,
          dirtyLocation: false
        })
      );
    } else {
      this.setState({activeTab: tabname})
    }
  })

  persistProject = (event) => {
    event.preventDefault();
    const {project: {id, ...project}={}} = this.props;
    this.setState({submitted: true, error: null, errorMsg: null});
    if (id && !this.state.copyMode) {
      ProjectActions.updateProjectSettings({...this.projectData(), id}, project);
    } else {
      ProjectActions.addProject({...project, ...this.projectData(), owned: true});
    }
  }

  projectData = () => {
    const {can_publish=false, is_admin=false} = UserStore.getInfo();
    return {
      ..._.pick(this.state, PROJECT_FIELDS),
      published: can_publish ? !this.state.isPrivate : undefined,
      verified: is_admin ? this.state.verified : undefined
    }
  }

  deleteProject = () => {
    ProjectActions.deleteProject(this.props.project);
  }

  render() {
    const {
      error, errorMsg, submitted, activeTab, mapKey, copyMode, 
      name, client, address, city, state, zipcode, country,
      isPrivate, verified, latitude, longitude
    } = this.state;
    const {online, project={}} = this.props;
    const {can_publish=false, is_admin=false, global_editor=false} = UserStore.getInfo();
    const readonly = project.id && !project.owned && !global_editor;
    return (
      <div>
        {error && (
          <div className="padding">
            <p className="alert error">{errorMsg}</p>
          </div>
        )}
        <form className="list" onSubmit={this.persistProject} disabled={submitted}>
          <FormRow label="Project Name" required={!readonly}>
            <input type="text" value={name} onChange={(e) => this.set('name', e.target.value)} disabled={readonly} /> </FormRow>

          <FormRow label="Client">
            <input type="text" value={client} onChange={(e) => this.set('client', e.target.value)} disabled={readonly} />
          </FormRow>
          <FormRow label=""/>

          <IonTabs tabsTop={true} customClasses="tabs-striped tabs-top tabs-color-positive subtabs">
            <a className={`tab-item ${activeTab === "address" ? "active" : ""}`} href="#" onClick={this.selectTab("address")}>Address</a>
            <a className={`tab-item ${activeTab === "coordinates" ? "active" : ""}`} href="#" onClick={this.selectTab("coordinates")}>Coordinates</a>
          </IonTabs>

          <div style={{display: activeTab !== "address" ? 'none' : null}}>
            <FormRow label="Address">
              <input type="text" value={address} onChange={(e) => this.set('address', e.target.value)} disabled={readonly} />
            </FormRow>

            <FormRow label="City">
              <input type="text" value={city} onChange={(e) => this.set('city', e.target.value)} disabled={readonly} />
            </FormRow>

            <FormRow label="State/Province">
              <input type="text" value={state} onChange={(e) => this.set('state', e.target.value)}  disabled={readonly} />
            </FormRow>

            <FormRow label="Postal Code" required={!readonly && !longitude && !latitude}>
              <input type="text" value={zipcode} onChange={(e) => this.set('zipcode', e.target.value)} disabled={readonly} />
            </FormRow>

            <FormRow label="Country" className="item-select">
              <select value={country} onChange={(e) => this.set('country', e.target.value)} disabled={readonly}>
                {countryOptions}
              </select>
            </FormRow>
          </div>

          <div style={{display: activeTab !== "coordinates" ? 'none' : null}}>
            <CoordinateSelection key={mapKey} location={{latitude, longitude}} onChange={this.set} readonly={readonly}/>
            {!readonly && online && (
              <div className="padding">
                <p className="alert info">
                  {latitude && longitude ? (
                    "Drag the map marker to change the project's location."
                  ) : (
                    "Click the project's location on the map to set the latitude and longitude."
                  )}
                </p>
              </div>
            )}
            <FormRow label="Latitude">
              <input type="text" value={latitude || ''} disabled={readonly} onChange={(e) => this.set('latitude', e.target.value)}/>
            </FormRow>

            <FormRow label="Longitude">
              <input type="text" value={longitude || ''} disabled={readonly} onChange={(e) => this.set('longitude', e.target.value)}/>
            </FormRow>
          </div>

          {can_publish && !readonly && (
            <IonItemCheckBox color="positive" label="Private" checked={isPrivate}
                handleChange={isPrivate => this.setState({isPrivate})} />
          )}

          {is_admin && (
            <IonItemCheckBox color="positive" label="Verified" checked={verified}
                handleChange={verified => this.setState({verified})} />
          )}

          {!readonly && <p className="padding form-required">* = required fields</p>}

          <div className="padding">
            {(is_admin || !readonly) && (
              <button className="button button-block button-positive" type="submit" disabled={submitted}>
                {submitted ? <IonSpinner icon="ios-small" /> : ''}
                SAVE {copyMode && 'COPY'}
              </button>
            )}
          </div>
        </form>
      </div>
    );
  }
}

export default withOnline(withRouter(ProjectForm));
