import { IonIcon } from 'reactionic';
import SoilLogCallout from './SoilLogCallout';

export default function SoilLogDeleteCallout({y, removeSoilLayer}) {
  return (
    <SoilLogCallout y={y}>
      <ul>
        <li onClick={removeSoilLayer}>
          <IonIcon icon="minus-circled"/> Remove Boundary
        </li>
      </ul>
    </SoilLogCallout>
  );
}
