import countries from 'i18n-iso-countries';
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

const countryList = countries.getNames("en", {select: "official"});

const PRIORITY_COUNTRIES = ["US", "CA"];
const prioritizedCountryList = _.uniqBy([
  ..._.map(PRIORITY_COUNTRIES, (code) => ({code, name: countryList[code]})),
  ..._.map(countryList, (name, code) => ({code, name}))
], 'code');

export default prioritizedCountryList.map(({code, name}) => (
  <option key={code} value={code}>{name}</option>
));
