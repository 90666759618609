import { MAX_LEVEL } from 'lib/constants';
import { pathify } from 'lib/svg-helpers';
import { autoScaleMax } from 'lib/number';

import Svg from 'components/helpers/svg';
import PatternDefs from 'components/graph/pattern-defs';
import Axes from 'components/graph/axes';
import Guide from 'components/graph/guide';
import GridLabel from 'components/graph/grid-label';
import Gridline from 'components/graph/gridline';
import DataLabel from 'components/graph/data-label';

const OFFSET_X = 20;
const OFFSET_Y = 10;
const GRAPH_WIDTH = 150;
const GRAPH_HEIGHT = 800;
const X_GRIDS = 5;
const GRAPH_DIMENTIONS = {OFFSET_X, OFFSET_Y, GRAPH_WIDTH, GRAPH_HEIGHT, X_GRIDS};

const invalidData = (data) => {
  return _.some(_.flatten(_.map(data, _.values)), _.negate(_.isFinite));
}

export default class DepthGraph extends React.Component {
  maxData = () => _.maxBy(this.props.data, 'x').x;

  scaleFactor = () => {
    const {guides, baseScale, unitX} = this.props;
    const maxGuide = _.max(_.values(guides)) || 0;
    return autoScaleMax(Math.max(this.maxData(), maxGuide), baseScale, unitX);
  }

  factorX = () => (GRAPH_WIDTH - OFFSET_X*2)/this.scaleFactor();

  factorY = () => 4;

  axisLabels = () => {
    const {data, unitX} = this.props;
    const maxLabel = _.round(unitX.toDisplay(this.scaleFactor()), 2);
    return _.times(X_GRIDS, n => ((maxLabel / X_GRIDS) * (n + 1)));
  }

  dataPath = (data) => {
    const {unitY} = this.props;
    return data.map(({x, y}) => {
      return {
        x: OFFSET_X + x * this.factorX(),
        y: OFFSET_Y + unitY.toBase(y * this.factorY())
      };
    });
  }

  render() {
    const {maxDim, data, envelope, guides, unitX, unitY, maxDepth} = this.props;
    const [factorX, factorY] = [this.factorX(), this.factorY()];

    if (invalidData(data)) {
      console.error('bad data', data);
      return null;
    }
    const graphHeight = GRAPH_HEIGHT * (maxDepth/MAX_LEVEL) + OFFSET_Y;
    return (
      <div className="depth-graph">
        <Svg width={GRAPH_WIDTH} height={graphHeight} maxDim={maxDim}>
          <PatternDefs key={_.uuid()}/>
          <g className="gridlines gridlines-x">
            {this.axisLabels().map((x) => (
              <Gridline key={x} value={x} factor={factorX} unit={unitX} GRAPH_DIMENTIONS={GRAPH_DIMENTIONS}/>
            ))}
          </g>
          {_.map(guides, (value, key) => (
            <Guide key={key}
              value={value}
              label={key}
              factorX={factorX}
              maxData={this.maxData()}
              GRAPH_DIMENTIONS={GRAPH_DIMENTIONS}/>
          ))}
          <Axes {...GRAPH_DIMENTIONS}/>
          <g className="labels labels-y">
            {data.map(({y}) => (
              <GridLabel key={y} y={_.round(y, 1)} factor={factorY} unit={unitY} GRAPH_DIMENTIONS={GRAPH_DIMENTIONS}/>
            ))}
          </g>
          <g className="labels labels-x">
            {this.axisLabels().map((x) => (
              <GridLabel key={x} x={x} factor={factorX} unit={unitX} GRAPH_DIMENTIONS={GRAPH_DIMENTIONS}/>
            ))}
          </g>
          {envelope && (
            <path className="std-plot" d={pathify([...this.dataPath(envelope[0]), ..._.reverse(this.dataPath(envelope[1]))])} />
          )}
          <path id="data-plot" d={pathify(this.dataPath(data))} />
          <g className="labels labels-data">
            {data.map(({x, y}) => {
              if (x > 0) return (
                <DataLabel key={y} {...{x, y, factorX, factorY, unitY, unitX, GRAPH_DIMENTIONS}}/>
              );
            })}
          </g>
        </Svg>
      </div>
    );
  }
}
