import store from 'store';
import {getCurrentProject} from './ProjectStore';
import HELIX_AREAS from 'config/helix-areas';


export function getProductTypes() {
  return _.get(store.getState(), 'products.productTypeList', []);
}

function dualFilter(segments) {
  const project = getCurrentProject();

  if (_.get(project, 'settings.pile.includeDualCutter')) return segments;

  return _.filter(segments, ({helices}) => !_.find(helices, {type: 'dual'}));
}

function filterLengths({minlength=0, maxlength}, segments) {
  maxlength = maxlength || 1e6;
  return _.filter(segments, (segment) => {
    return segment.length >= minlength && segment.length <= maxlength;
  });
}

function pickClean(obj, ...propNames) {
  return _.pickBy(_.pick(obj, propNames), _.identity);
}

export function filterLeadList(segments, options={}) {
  options = {...options, length: options.length ? parseInt(options.length, 10) : null};
  const leads = _.filter(segments, {lead_extension: 'Lead', ...pickClean(options, 'diameter', 'pitch', 'coupling', 'coating', 'wall')});
  return dualFilter(filterLengths({maxlength: options.pileLength*12}, leads));
}

export function getLeadList(options={}) {
  return filterLeadList(_.get(store.getState(), 'products.segmentList'), options);
}

export function getSegmentList(options={}, lead={}) {
  const lead_filters = _.pick(lead, 'coupling', 'wall');
  const segments = _.get(store.getState(), 'products.segmentList');

  return dualFilter(filterLengths(options, _.filter(segments, {lead_extension: 'Extension', ...lead_filters})));
}

export function getLeadBySKU(sku) {
  return this.getSegmentBySKU(sku);
}

export function getSegmentBySKU(sku) {
  return _.find(getSegmentList(), {sku});
}

export const subscribe = store.subscribe;

export default {
  subscribe, getSegmentList, getProductTypes, getLeadList,
  getLeadBySKU, getSegmentBySKU
}
