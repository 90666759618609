import { Link } from 'react-router';
import { IonList } from 'reactionic';
import ProjectStore from 'stores/ProjectStore';
import ProjectActions from 'actions/ProjectActions';
import GenericOption from 'components/helpers/GenericOption';

const SORT_OPTIONS = ["Most recent first", "Alphabetical"];

const sortProjects = (sortOrder) => {
  if (sortOrder == "Most recent first") {
    return ProjectStore.getProjects();
  } else if (sortOrder == "Alphabetical") {
    return _.sortBy(ProjectStore.getProjects(), ['name']);
  }
};

export default class ProjectList extends React.Component {
  state = {
    projectList: ProjectStore.getProjects(),
    sortOrder: _.first(SORT_OPTIONS),
  }

  componentDidMount() {
    this.unsubscribeProjectStore = ProjectStore.subscribe(() => {
      this.setState({projectList: ProjectStore.getProjects()});
    });
    ProjectActions.getProjects();
  }

  componentWillUnmount() {
    this.unsubscribeProjectStore();
  }

  updateSortOrder = (event) => {
    this.setState({sortOrder: event.target.value});
  }

  render() {
    const {projectList, sortOrder} = this.state;

    return (
      <IonList>
        <label className="item item-input item-select">
          <span className="input-label">Sort by: </span>
          <select onChange={this.updateSortOrder} value={sortOrder} className="project-sort-select">
            {_.map(SORT_OPTIONS, (option) => (
              <GenericOption key={option} label={option} value={option}/>
            ))}
          </select>
        </label>
        <Link to='/projects/new' className="item item-icon-left new-project">
          <i className="icon ion-plus" />
          New Project
        </Link>
        {sortProjects(sortOrder).map(({id, name}) => (
          <Link key={id} to={`/projects/${id}/`} className="item item-icon-right">
            {name}
            <i className="icon ion-chevron-right" />
          </Link>
        ))}
      </IonList>
    );
  }
};
