export default function publicProjects(state, action) {
  switch(action.type) {
    case 'RESET':
      return []
    case 'RECEIVED_PUBLIC_PROJECT_LIST':
      return action.projectList;
    default:
      return state;
  }
}
