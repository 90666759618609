import classnames from 'classnames';
import { IonView, IonContent, IonTabs, IonTab } from 'reactionic';

export default function ProjectsLayout({location, children}) {
  const isGlobalRoute = location.pathname === '/global-projects';
  return (
    <IonView>
      <IonTabs tabsTop={true} customClasses="tabs-striped tabs-top tabs-background-dark tabs-color-light tabs-no-headerbar">
        <IonTab to="/projects" label="My Projects" />
        <IonTab to="/global-projects" label="Global Projects" />
      </IonTabs>
      <IonContent customClasses={classnames("has-tabs-top", {'no-scroll': isGlobalRoute})}>
        {children}
      </IonContent>
    </IonView>
  );
}
