import LOGO from 'images/magnum-geoapp-logo.png';

const CURRENT_YEAR = new Date().getFullYear();

export default function Page({number, children}) {
  return (
    <div className="page">
      <div className="page-header">
        <img src={window.location.origin + LOGO} className="uk-float-right logo" />
        <h1>MAGNUM&reg; GeoApp&reg; Version {VERSION}</h1>
        <h2>PILE DESIGN SUMMARY REPORT</h2>
        <p>Helical Pile Sizing Calculations</p>
      </div>
      <div className="page-content">
        { children }
      </div>
      <div className="page-footer">
        <p>
          <strong>Magnum Piering, Inc.</strong><br />
          156 Circle Freeway Dr.<br />
          Cincinnati, OH 45246<br />
          800-822-7437<br />
          www.magnumpiering.com
        </p>

        <p>MAGNUM GeoApp&reg; Software, Version {VERSION}, Copyright {CURRENT_YEAR}.&nbsp; <b>Page MP{number}</b></p>
      </div>
    </div>
  );
}
