import { Link } from 'react-router';
import AuthenticationActions from 'actions/AuthenticationActions';

export default class Logout extends React.Component {
  componentDidMount() {
    AuthenticationActions.logout()
  }

  render() {
    return (
      <div className="padding">
        <p>You are now logged out.</p>
        <Link to="/login" className="button">Sign in</Link>
      </div>
    );
  }
}
