import API from 'API';
import store from 'store';

export default {
  loadUserInfo: API.getCurrentUser,

  passwordReset(email) {
    API.passwordReset(email);
  },

  changePassword(newPassword, token, email) {
    API.changePassword(newPassword, token, email);
  },

  update: API.updateUser,
  requestDestroy: API.requestDestroyUser,
  delete: API.deleteUser,
  deleteUnauthenticated: API.deleteUnauthenticatedUser,

  clearUserInfo() {
    store.dispatch({type: 'CLEAR_USER_INFO'});
  },

  setSoilHelpSetting(setting) {
    API.setSoilHelpSetting(setting);
  }
};
