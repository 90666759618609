import * as DEFAULTS from 'reducers/projects/defaults';
import { BLOW_COUNT_INTERVAL } from 'lib/constants';
import {soilTestUnit} from 'config/soil-test-units';
import UnitLabels from 'config/unit-labels';
import LengthUnits from 'config/length-units';
import SmallLengthUnits from 'config/small-length-units';
import ForceUnits from 'config/force-units';
import { testUnitConversion } from 'lib/capacity-calculations';

import data from 'reducers/project/data';
import settings from 'reducers/project/settings';

export function optimize(state, action) {
  if (action.type === 'RECEIVED_NEW_UNIT_TYPE') {
    if (action.unitType !== state.settings.unitType) {
      const unitSetting = _.find(UnitLabels, {value: action.unitType});
      if (! unitSetting) return {type: '__NOOP'};

      const system = unitSetting.system;
      const lengthUnit = _.find(LengthUnits, {system});
      const smallLengthUnit = _.find(SmallLengthUnits, {system});
      const forceUnit = ForceUnits[unitSetting.forceUnit];
      const interval = BLOW_COUNT_INTERVAL[system];

      return {...action, system, lengthUnit, smallLengthUnit, forceUnit, interval};
    }
    return {type: '__NOOP'};
  }
  return action;
}

export default function project(state=DEFAULTS.PROJECT, dumbAction) {
  const action = optimize(state, dumbAction);
  switch(action.type) {
    case 'UPDATE_PROJECT_PROPERTIES':
      if (state.readonly) return state;

      if (state.id === action.project.id) {
        return {...state, ...action.project};
      }

      return state;
    case 'RECEIVED_NEW_UNIT_TYPE':
    case 'RECEIVED_NEW_TEST_UNIT_TYPE':
      const {
        settings: {testUnit:oldUnit, system},
        data: {blowCounts, boundaries, soilTypes}
      } = state;
      const newUnit = soilTestUnit(action.testUnitType || oldUnit.value, action.system || system);
      const unitConversion = testUnitConversion(oldUnit, newUnit, boundaries, soilTypes);
      state['data']['blowCounts'] = blowCounts.map(unitConversion);
    default:
      return {
        ...state,
        settings: settings(state.settings, action),
        data: state.readonly ? state.data : data(state.data, action)
      };
  }
}
