export default {
  "feet": {
    "system": "english",
    "value": "feet",
    "label": "feet",
    "abbreviation": "'",
    "toBase": function(value) {
      return value/1;
    },
    "toDisplay": function(value) {
      return value/1;
    }
  },
  "meters": {
    "system": "metric",
    "value": "meters",
    "label": "meters",
    "abbreviation": "m",
    "toBase": function(value) {
      return value*3.28084;
    },
    "toDisplay": function(value) {
      return value/3.28084;
    }
  }
}
