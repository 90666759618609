import UnitLabels from 'config/unit-labels';
import LengthUnits from 'config/length-units';
import SmallLengthUnits from 'config/small-length-units';
import ForceUnits from 'config/force-units';
import {soilTestUnit} from 'config/soil-test-units';
import { MAX_LEVEL, BLOW_COUNT_INTERVAL, CURRENT_SCHEMA_VERSION } from 'lib/constants';

export const PILE = {
  diameter: 3.0,
  coupling: 'Bolted',
  coating: 'Galvanized',
  pitch: 3.0,
  length: 72.0,
  wall: 0.25,
  includeDualCutter: true
};

const SYSTEM = 'english';
const defaultInterval = BLOW_COUNT_INTERVAL[SYSTEM];
export const SETTINGS = {
  system: SYSTEM,
  unitType: 'tons-feet',
  lengthUnit: LengthUnits['feet'],
  smallLengthUnit: SmallLengthUnits['inches'],
  forceUnit: ForceUnits['tons'],
  interval: defaultInterval,
  testUnit: soilTestUnit('SPT 70', SYSTEM),
  includeShaftFriction: false,
  reduceShaftFriction: false,
  aboveGrade: 0,
  pile: PILE
};
export const LOADS = {
  compression: null,
  tension: null,
  lateral: null
};
export const SOIL_TYPES = Object.freeze([
  {id: 0, name: 'sand'}
]);
export const DEFAULT_BLOW_COUNT = Object.freeze(_.times(MAX_LEVEL/defaultInterval + 1, _.constant(0)));
export const DATA = Object.freeze({
  schema: CURRENT_SCHEMA_VERSION,
  safetyFactor: 2,
  loads: LOADS,
  blowCounts: DEFAULT_BLOW_COUNT,
  boundaries: Object.freeze([MAX_LEVEL]),
  soilTypes: SOIL_TYPES,
  pileSegments: [],
  pileCount: 1,
  bulkSoil: null
});

export const PROJECT = Object.freeze({settings: SETTINGS, data: DATA});

export const DEFAULT_PROJECT_STATUS = {
  error: false,
  errorMsg: "",
  projectID: null
};
