import { IonIcon } from 'reactionic';
import ProjectSettingsActions from 'actions/ProjectSettingsActions';

export default function SafetyFactor({safetyFactor, readonly, help}) {
  const changeSafetyFactor = (event) => ProjectSettingsActions.setSafetyFactor(event.target.value);

  return (
    <label className="item item-input">
      <span className="input-label" onClick={help}>
        Safety Factor <IonIcon icon="help-circled"/>:
      </span>
      {readonly ? (
        <span className="readonly-value">{safetyFactor}</span>
      ) : (
        <input type="number" min="1" step="0.5" value={safetyFactor} onChange={changeSafetyFactor}/>
      )}
    </label>
  );
}
