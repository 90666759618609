import ReactDataSheet, {DataEditor} from 'react-datasheet';
import { BLOW_COUNT_INTERVAL_E, BLOW_COUNT_INTERVAL, MAX_LEVEL } from 'lib/constants';
import SoilTypeDropdown from './SoilTypeDropdown';
import SoilLabels from 'config/soil-labels.js';
import 'react-datasheet/lib/react-datasheet.css';
import parseSoil, {validateData}  from 'lib/bulk-soil-parse';

const DEFAULT_ROWS = MAX_LEVEL / BLOW_COUNT_INTERVAL_E + 1;
const MIN_COLUMNS = 3;

const SOIL_LABELS = _.reduce(SoilLabels, (acc, soilLabel) => ({
  ...acc, [soilLabel.value]: soilLabel.label
}), {});

const defaultSoilRows = (bulkSoil, {lengthUnit, testUnit, system}) => {
  const boringColumns = bulkSoil ? bulkSoil[0].length - 2 : 1;
  const header = [
    {value: `Depth (${lengthUnit.abbreviation})`, readOnly: true},
    {value: 'Soil Type', readOnly: true},
    ..._.times(boringColumns, (n) => boringHeadingCell(n + 1, testUnit.unit))
  ];
  const data = bulkSoil ? _.drop(bulkSoil, 1) :
    _.times(DEFAULT_ROWS, (index) => [
      {value: _.round(index * BLOW_COUNT_INTERVAL[system], 1), className: "number-cell"},
      {value: '', className: "soil-value-cell"},
      boringCell
    ]);
  return [header, ...data];
}

const boringHeadingCell = (boringNumber, soilTestUnit) => (
  {value: `Boring ${boringNumber} (${soilTestUnit})`, readOnly: true}
);
const boringCell = {value: '', className: "number-cell"};


const fillSoilType = (data) => {
  let lastValue = "";
  return _.map(data, ([depth, type, ...cols], i) => {
    lastValue = i > 0 ? type.value || lastValue : "";
    return [depth, {...type, lastValue}, ...cols];
  });
}
const soilTypeValue = (cell) => SOIL_LABELS[cell.value || cell.lastValue] || cell.value;

export default class BulkImport extends React.Component {
  state = {
    errors: [],
    data: defaultSoilRows(_.get(this.props, 'project.data.bulkSoil'), _.get(this.props, 'project.settings'))
  }

  save = () => {
    const [header, ...data] = this.state.data;
    this.props.save({...parseSoil(data, _.get(this.props, 'project.settings')), rawData: this.state.data});
  }

  addColumn = () => {
    this.setState(({data}) => ({data: data.map((row, i) => {
      if (i === 0) return [...row, boringHeadingCell(row.length - 1, _.get(this.props, 'project.settings.testUnit.label'))];
      return [...row, boringCell];
    })}));
  }
  removeColumn = () => {
    this.setState(({data}) => ({data: data.map(_.dropRight)}));
  }

  render() {
    const {data, errors} = this.state;

    return (
      <div className="bulk-import-modal">
        {errors && _.map(errors, (error) => <p className="error" key={error}>{error}</p>)}
        {data[0].length > MIN_COLUMNS && (
          <button
            className="button button-small button-warning float-right"
            onClick={this.removeColumn}
          >
            Remove Boring
          </button>
        )}
        <button
          className="button button-small float-right"
          onClick={this.addColumn}
        >
          Add Boring
        </button>
        <br style={{clear: "both"}}/>
        <ReactDataSheet
          data={fillSoilType(data)}
          attributesRenderer={(cell) => ({title: cell.error, "data-blank": cell.value.length === 0})}
          valueRenderer={(cell, row, col) => col === 1 ? soilTypeValue(cell) : cell.value}
          dataEditor={(props) => (
            props.col === 1 ? <SoilTypeDropdown {...props} /> : <DataEditor {...props} />
          )}
          onCellsChanged={(changes) => {
            const grid = _.cloneDeep(data);
            changes.forEach(({ cell, row, col, value }) => {
              grid[row][col] = { ...grid[row][col], value };
            });
            this.setState(validateData({data: grid}, this.props.project.settings));
          }}
        />
        <button className="save-button"
          onClick={() => _.defer(this.save)}
          disabled={!!errors.length || !data[1][1].value}
        >
          Save
        </button>
      </div>
    );
  }
}
