import PropTypes from 'prop-types';
import {
  IonBody, IonNavView, IonButton,
  IonSideMenuContainer, IonSideMenus, IonSideMenuContent
} from 'reactionic';
import UserStore from 'stores/UserStore';
import MainNav from 'components/MainNav';
import MAGNUM_LOGO from 'images/magnum-geoapp-logo.png';
import VersionChecker from './VersionChecker';

class NavButton extends React.Component {
  static contextTypes = {
    ionSnapper: PropTypes.object
  }

  render() {
    const toggleMenu = () => this.context.ionSnapper.toggle('right');
    return <IonButton icon="ion-navicon" customClasses='float-right' onClick={toggleMenu} />
  }
}

const SIDE_MENU_SETTINGS = {disable: 'left', hyperextensible: false, touchToDrag: false};

export default class MainLayout extends React.Component {
  state = {
      loggedIn: UserStore.loggedIn(),
      online: UserStore.getState().online
  }

  componentDidMount() {
    this.unsubscribe = UserStore.subscribe(() => {
      this.setState({
        loggedIn: UserStore.loggedIn(),
        online: UserStore.getState().online
      });
    });
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  render() {
    const user = UserStore.getInfo();

    return (
      <IonBody location={this.props.location}>
        <IonSideMenuContainer {...this.props} settings={SIDE_MENU_SETTINGS}>
          <IonSideMenus>
            <MainNav user={user} loggedIn={this.state.loggedIn}/>
          </IonSideMenus>
          <IonSideMenuContent>
            <div className="container">
              <header>
                <NavButton/>
                <img src={MAGNUM_LOGO} className="logo" />
                {!this.state.online && <p className="offline-indicator">Offline</p>}
                <p className="version">Version {VERSION}</p>
                <VersionChecker />
              </header>
              <IonNavView customClasses="main">
                {this.props.children || (
                  <div className="padding">
                    <p>You are {!this.state.loggedIn && 'not'} logged in.</p>
                  </div>
                )}
              </IonNavView>
            </div>
          </IonSideMenuContent>
        </IonSideMenuContainer>
      </IonBody>
    );
  }
}
