import { IonItemCheckBox } from 'reactionic';
import UserActions from 'actions/UserActions';

export default function SoilLogHelp() {
  return (
    <div>
      Tap soil log to change soil type.<br />
      Tap and hold log and soil delineator will appear.<br />
      Drag delineator to proper depth.<br />
      Drag sample circles to representative soil strength test results at that depth.<br />
      Adjust soil test type under loads tab.<br />
      <br />
      <IonItemCheckBox color="positive"
          label="Don't display this again"
          checked={false}
          handleChange={UserActions.setSoilHelpSetting}
      />
    </div>
  );
}
