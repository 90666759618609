import store from 'store';

function getCurrentProjectId() {
  return _.get(store.getState(), 'projectStatus.projectID');
}

export function getPublicProjects() {
  return _.get(store.getState(), 'publicProjects', []);
}

export function getSettings(key) {
  const name = _.compact(['settings', key]).join('.');
  return _.get(getCurrentProject(), name);
}

export function getData(key) {
  const name = _.compact(['data', key]).join('.');
  return _.get(getCurrentProject(), name);
}

export function getProjectStatus() {
  return _.get(store.getState(), 'projectStatus', {});
}

export function getProjects() {
  return _.reject(_.get(store.getState(), 'projects', []), 'deletedOfflineAt') || [];
}

export function getCurrentProject() {
  return getProjectById(getCurrentProjectId());
}

export function getProjectById(id) {
  if (id) return  _.find(getProjects(), {id}) || _.find(getProjects(), {id: parseInt(id, 10)});
}

export const subscribe = store.subscribe


export default {
  getPublicProjects, getSettings, getData, getProjectStatus,
  getProjects, getCurrentProject, getProjectById, subscribe
};
