import Repository from 'repository';
import store from 'store';
import API from 'API';

export function getSegmentList(state, lead={}) {
  const lead_filters = _.pick(lead, 'coupling', 'wall', 'pitch');
  const segments = _.get(state, 'products.segmentList');

  return {..._.first(_.filter(segments, {lead_extension: 'Extension', helices: [], ...lead_filters})), length: 12};
}

const currentProject = (state) => {
  const projectId = _.get(state, 'projectStatus.projectID');
  const projects = _.get(state, 'projects');
  return _.find(projects, {id: projectId}) || _.find(projects, {id: parseInt(projectId, 10)});
}

export default {
  setFilters(filters) {
    store.dispatch({type: 'SET_PILE_FILTER', ...filters});
    Repository.persistProject();
  },

  resetPileSegments() {
    store.dispatch({
      type: 'RESET_PILE_SEGMENTS'
    });
    Repository.persistProject();
  },

  addSegment(segment) {
    store.dispatch({
      type: 'ADD_SEGMENT',
      selectedSegment: segment
    });
    Repository.persistProject();
  },

  removeExtension(id) {
    store.dispatch({
      type: 'REMOVE_EXTENSION',
      id
    });
    Repository.persistProject();
  },

  setLead(lead, pileLength) {
    if (!pileLength) throw new Error('must have pileLength')
    return (dispatch, getState) => {
      dispatch({type: 'SET_PILE', lead, pileLength});
      Repository.persistProject();
    };
  },

  setLength(pileLength) {
    return (dispatch, getState) => {
      const project = currentProject(getState());
      const [lead, ...extensions] = _.get(project, 'data.pileSegments', []);

      dispatch({type: 'SET_PILE_FILTER', pileLength});

      if (lead) {
        if (lead.shaftLength > pileLength) {
          dispatch({type: 'RESET_PILE_SEGMENTS'});
        } else {
          dispatch({type: 'SET_PILE', lead, pileLength});
        }
        Repository.persistProject();
      }
    };
  },

  getSegments: API.getSegments,

  getProductTypes: API.getProductTypes
};
