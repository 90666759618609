import UnitLabels from 'config/unit-labels';
import ProjectSettingsActions from 'actions/ProjectSettingsActions';
import GenericOption from 'components/helpers/GenericOption';

export default function LoadUnitSelector({settings}) {
  const {unitType:selected} = settings;
  const changeUnits = (event) => ProjectSettingsActions.setUnits(event.target.value);

  return (
    <label className="item item-input item-select">
      <span className="input-label">
        Primary units:
      </span>
      <select onChange={changeUnits} value={selected}>
        {UnitLabels.map((unitOption) => (
          <GenericOption key={unitOption.value} {...unitOption}/>
        ))}
      </select>
    </label>
  );
}
