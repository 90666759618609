import SoilLabels from 'config/soil-labels';
import SoilLogCallout from './SoilLogCallout';

export default function SoilLogInfoCallout({x, y, selectedSoil, startLevel, endLevel}) {
  const {label='undefined'} = _.find(SoilLabels, {value: selectedSoil}) || {};

  return (
    <SoilLogCallout y={y} x={x}>
      <ul>
        <li>{label}</li>
      </ul>
    </SoilLogCallout>
  );
}
