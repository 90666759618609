import { MAX_LEVEL } from 'lib/constants';

export const MAX_HELICES = 50;

export const helixDiametersFor = ({diameter, wall}) => {
  if (!diameter) return [];
  if (diameter < 2) return [8, 10, 12, 14];
  if (diameter < 4) return [8, 10, 12, 14, 16];
  if (diameter < 5) return [10, 12, 14, 16, 20, 24];
  if (diameter < 7) return [12, 14, 16, 20, 24, 30, 36];
  if (diameter < 8) return [14, 16, 20, 24, 30, 36];
  if (diameter < 10) return [16, 20, 24, 30, 36, 42];
  if (diameter < 18) return [20, 24, 30, 36, 42];
  return [24, 30, 36, 42];
}

const REQUIRED_CONFIG = ['shaft_diameter', 'alt_spacing_size', 'spacing', 'alt_spacing'];

export const configValid = (config) => _.every(REQUIRED_CONFIG, (key) => !_.isNil(_.get(config, key)));

export const warnMissingParameters = _.memoize((leadPrototype) => {
  if (!leadPrototype) return;
  (window.Rollbar?.options?.enabled ? Rollbar : console).warn("Missing Custom Helix Spacing For Pile", leadPrototype)
});

export const maxHelixSpacingFor = ({spacing, alt_spacing}) => Math.max(spacing, alt_spacing);

export const helixHeight = (pile) => {
  const pitchFactor = pile.pitch == 6 ? 0.95 : 0.7;
  const lastHelixHeight = _.get(_.last(pile.helices), 'diameter', 0) * pitchFactor;
  const minPileLength = _.reduce(_.map(pile.helices, "spacing"), _.add, 0) + lastHelixHeight;
  return _.ceil(minPileLength / 12) + 1;
}

export const maxHelices = (config) => {
  if (!configValid(config)) return;
  return Math.min(_.floor(MAX_LEVEL*12/maxHelixSpacingFor(config)), MAX_HELICES);
}

const calculateSpacing = ({shaft_diameter, alt_spacing_size, spacing, alt_spacing}) => {
  return (helix, index) => ({
    ...helix,
    spacing: index === 0 ? shaft_diameter + 1 : (
      helix.diameter < alt_spacing_size ? spacing : alt_spacing
    )
  });
};

const limitHelices = (config, helices) => _.take(
  _.reject(helices, 'draft'), maxHelices(config)
);
const setHelices = (config, pile, helices) => ({...pile,
  helices: limitHelices(config, helices).map(calculateSpacing(config))
});
const markCustom = (pile) => ({...pile,
  custom: true
});
const setHelixConfig = (pile) => ({...pile,
  helixConfig: pile.helices.map(({diameter, type}) => `${diameter}${_.first(type).toUpperCase()}`)
});
const setHelixSpacing = (pile) => ({...pile,
  helix_spacing: _.zipObject(_.range(1, Math.min(7, pile.helices.length)+1), _.map(pile.helices, 'spacing'))
});
const generateSKU = ({sku, ...pile}) => ({...pile,
  sku: sku.replace(/(.*\dK).*?([6NG]+.*)/, `$1${pile.helixConfig.join('')}$2`)
});

export const createLead = _.flow(
  setHelices,
  markCustom,
  setHelixConfig,
  setHelixSpacing,
  generateSKU
);
