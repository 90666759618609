import { MAX_LEVEL } from 'lib/constants';
import { pathify, groupTranslate } from 'lib/svg-helpers';
import SoilLabels from 'config/soil-labels';
import SoilActions from 'actions/SoilActions';

import SoilSelector from './SoilSelector';
import SoilBoundary from './SoilBoundary';
import { EventTargetProxy, stopPropagation } from '../../helpers/event';

export default class SoilList extends React.Component {
  groupTranslate = groupTranslate(this.props)
  
  eventTargetProxy = new EventTargetProxy()

  updateSoilType = (i) => (newValue) => {
    const newSoilTypes = _.cloneDeep(this.props.soilTypes);
    newSoilTypes[i].name = newValue;
    SoilActions.setSoilTypes(newSoilTypes);
  }

  updateSoilBoundary = (i) => (newValue) => {
    const newBoundaries = _.cloneDeep(this.props.boundaries);
    const minLevel = (newBoundaries[i-1] || 0) + 1;
    const maxLevel = (newBoundaries[i+1] || MAX_LEVEL) - 1;
    newBoundaries[i] = _.clamp(newValue/1, minLevel, maxLevel);
    SoilActions.setSoilBoundaries(newBoundaries);
  }

  splitSoilLayer = (i) => (newLevel) => {
    newLevel = _.cloneDeep(newLevel || this.getMiddleOfLayer(i));
    const newBoundaries = _.cloneDeep(this.props.boundaries);
    const newSoilTypes = _.cloneDeep(this.props.soilTypes);
    const newSoilType = _.cloneDeep(newSoilTypes[i]);
    const newId = _.maxBy(newSoilTypes, 'id').id + 1;
    newSoilType.id = newId;

    newSoilTypes.splice(i, 0, newSoilType);
    if (this.isNewLevelValid(i, newLevel)) {
      newBoundaries.splice(i, 0, newLevel);
    }

    SoilActions.setSoilTypes(newSoilTypes);
    SoilActions.setSoilBoundaries(newBoundaries);
  }

  isNewLevelValid = (i, newLevel) => {
    return newLevel > (this.props.boundaries[i-1] || 0) && newLevel < this.props.boundaries[i];
  }

  getMiddleOfLayer = (i) => {
    const newBoundaries = _.cloneDeep(this.props.boundaries);
    const startLevel = newBoundaries[i-1] || 0;
    return Math.round(startLevel + (newBoundaries[i] - startLevel)/2);
  }

  removeSoilLayer = (i) => () => {
    if (!this.canRemoveSoilLayer()) return;
    if (this.props.soilTypes.length > 1) {
      let newSoilTypes = _.cloneDeep(this.props.soilTypes);
      newSoilTypes.splice(i, 1)
      SoilActions.setSoilTypes(newSoilTypes);

      let newBoundaries = _.cloneDeep(this.props.boundaries);
      newBoundaries.splice(i, 1);
      newBoundaries[newBoundaries.length-1] = MAX_LEVEL;
      SoilActions.setSoilBoundaries(newBoundaries);
    }
  }

  canRemoveSoilLayer = () => this.props.boundaries.length > 1

  soilLevels = () => this.props.boundaries.map((boundary, i, boundaries) => {
    const startLevel = boundaries[i-1] || 0;
    return {
      lastItem: i+1 == boundaries.length,
      startLevel,
      endLevel: boundaries[i],
      soilType: this.props.soilTypes[i] || {id: `undefined-${i}`, name: 'undefined'},
      minLevel: Math.min(startLevel + 1, MAX_LEVEL),
      maxLevel: (boundaries[i+1] - 1) || MAX_LEVEL
    };
  })

  getActualHeight() {
    if (!this.eventTargetProxy.domnode.current) return 0;
    return this.eventTargetProxy.domnode.current.getBoundingClientRect().height;
  }

  setCallout = ({level, ...props}={}) => {
    const {factorY, offsetY, setCallout} = this.props;
    const actualHeight = this.getActualHeight();
    const pixelRatio = actualHeight / (MAX_LEVEL * factorY);
    setCallout({y: (level * factorY + offsetY) * pixelRatio, level, ...props});
  }

  render() {
    const {factorY, readonly, setCallout} = this.props;
    const soilLevels = this.soilLevels();
    return (
      <g className="soil-list" transform={this.groupTranslate()} onClick={stopPropagation} ref={this.eventTargetProxy.domnode}>
        {soilLevels.map((level, i) => (
          <SoilSelector
            key={level.soilType.id}
            soilType={level.soilType.name}
            soilLabels={SoilLabels}
            startLevel={level.startLevel}
            endLevel={level.endLevel}
            factorY={factorY}
            readonly={readonly}
            setCallout={this.setCallout}
            updateSoilType={this.updateSoilType(i)}
            splitSoilLayer={this.splitSoilLayer(i)} />
        ))}
        {soilLevels.map((level, i) => {
          if (level.lastItem) return;
          return <SoilBoundary
            key={level.soilType.id}
            level={level.endLevel}
            parentEventTarget={this.eventTargetProxy}
            updateSoilBoundary={this.updateSoilBoundary(i)}
            removeSoilLayer={this.removeSoilLayer(i)}
            setCallout={this.setCallout}
            factorY={factorY}
            readonly={readonly} />;
        })}
      </g>
    );
  }
}
