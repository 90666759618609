import HELIX_AREAS from 'config/helix-areas';
const INCHES_IN_FEET = 12;
const inToFt = (inches) => inches / INCHES_IN_FEET;

export default {
  helix(helix, segment, cumulativeOffset) {
    const helixPosition = inToFt(helix.spacing + cumulativeOffset);
    helix.positionFromTop = segment.positionFromTop + (segment.shaftLength - helixPosition);
    helix.positionFromBottom = segment.positionFromBottom + helixPosition;
    helix.area = HELIX_AREAS[helix.type][helix.diameter];
    return helix;
  },

  shafts(segments=[]) {
    segments = _.cloneDeep(segments || []);

    const totalLength = this.totalLength(segments);
    let cumulativeOffset = 0;
    return _.flatMap(_.reverse(segments), (segment) => {
      segment.shaftLength = inToFt(segment.length);
      segment.positionFromTop = cumulativeOffset;
      segment.positionFromBottom = totalLength - cumulativeOffset - segment.shaftLength;

      cumulativeOffset += segment.shaftLength;
      if (!_.isEmpty(segment.helices)) {
        const helixHeight = _.round(inToFt(_.reduce(segment.helices, (total, helix) => total + helix.spacing, segment.pitch)));
        if (helixHeight < segment.shaftLength) {
          const extension = this.extensionFromLead(segment);
          const nonHelixLength = segment.shaftLength - helixHeight;
          const startingId = _.maxBy(segments, ({id}) => id)?.id + 1;

          return [
            ..._.times(nonHelixLength, (n) => ({
              ...extension,
              id: n + startingId,
              shaftLength: 1,
              positionFromTop: segment.positionFromTop + n,
              positionFromBottom: segment.shaftLength - 1 - n
            })),
            {...segment, shaftLength: helixHeight, length: helixHeight * INCHES_IN_FEET, positionFromTop: cumulativeOffset - helixHeight}
          ];
        }
      }
      return segment;
    });
  },

  helices(segments=[]) {
    let cumulativeOffset = 0;
    return _.flatMap(this.shafts(segments), (segment) => {
      return _.map(segment.helices, (helix) => _.tap(
        this.helix(helix, segment, cumulativeOffset), () => {
          cumulativeOffset += helix.spacing;
        }
      ));
    });
  },

  maxTorque(segments=[]) {
    if (segments.length) {
      return _.minBy(segments, 'torque').torque; // the weakest link
    }
  },

  totalLength(segments=[]) {
    return inToFt(_.sumBy(segments, 'length'));
  },

  extensionFromLead(lead) {
    return {..._.pick(lead, ['capacity', 'coupling', 'diameter', 'max_torque', 'pitch', 'torque', 'wall']), length: 12};
  },

  extensionsForLength(extension, extensionLength, startId=2) {
    return _.times(extensionLength, (n) => ({...extension, id: n + startId}));
  }
}
