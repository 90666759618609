import { pathify } from 'lib/svg-helpers';

export default ({value, factor, unit, GRAPH_DIMENTIONS}) => {
  const {OFFSET_X, OFFSET_Y, GRAPH_HEIGHT} = GRAPH_DIMENTIONS;
  const coordX = OFFSET_X + (unit && unit.toBase(value * factor) || value * factor);
  const path = [
    {x: coordX, y: OFFSET_Y},
    {x: coordX, y: (OFFSET_Y + GRAPH_HEIGHT)}
  ];
  return <path className="gridline" d={pathify(path)}/>;
}
