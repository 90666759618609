export const terms = `By accepting these terms and conditions, user agrees to hold Magnum
  harmless from or against any legal action. Software is intended to be a guide
  and not to replace professional engineering. It should be used for preliminary
  sizing only. Final design should be made by a licensed professional engineer.
  Data entered from software projects will be saved on Magnum servers and is the
  exclusive property of Magnum Piering, Inc. Data will automatically appear on
  the global map; user personal and contact information will not be shared.
  Boring information and pile sizing will be available to all users. All users
  agree with this data sharing policy. This software is the copyright property
  of Magnum Piering, Inc. Reverse engineering, copying, redistribution, use of
  the software for unintended purposes is hereby prohibited. Contact Magnum
  Piering, Inc. for more information. By selecting material request quote at the
  end is considered permission to have a Magnum representative contact the user
  to solicit products or services.`;

export const userInfo = `User information is used to file and sort projects. It also
  appears on calculation output reports and request quote forms. User information
  is the exclusive property of Magnum Piering, Inc. and is held is strict
  confidentiality. User information is not shared with other users nor is it
  disclosed to any third party without the exclusive written consent of the user
  and Magnum Piering, Inc.`;

export const projectInfo = `Project information is used to file and sort projects. It will
  appear in global searches along with soil log, load, pile information and 
  results. Project information is the exclusive property of Magnum Piering, Inc.
  and is shared with all users. User agrees to disclosure of project information
  globally and shall hold Magnum Pierings, Inc., its agents and subsideraries,
  harmless from any issues arising from disclosure of such information.`;
