import SmallLengthUnits from 'config/small-length-units';

export const smallUnitConversion = (inches, unit) => {
  return _.round(unit.toDisplay(SmallLengthUnits['inches'].toBigUnit(inches)), 2);
};
export const displayForUnit = (unit) => (value, precision=1) => `${_.round(unit.toDisplay(value), precision)} ${unit.label}`;
export const smallUnitDisplay = (inches, unit) => `${_.round(unit.toDisplay(inches), 2)}${unit.abbreviation}`;
export const smallUnitDisplayAsBig = (inches, unit) => `${smallUnitConversion(inches, unit)}${unit.abbreviation}`;

export const pitchLabelForUnit = (unit) => (pitch) => `${_.round(unit.toDisplay(pitch), 2)} ${unit.label}`;
export const lengthLabelForUnit = (unit) => (length) => `${smallUnitConversion(length, unit)} ${unit.label}`;
