import project from 'reducers/project';

export default function(appState, action) {
  const currentProjectId = _.get(appState, 'projectStatus.projectID');
  const currentProject = (project) => project.id == currentProjectId;
  const global_editor = _.get(appState, 'user.userInfo.global_editor');

  return {
    ...appState,
    projects: _.map(appState.projects, (state) => {
      return currentProject(state) ? project({...state, readonly: !state.owned && !global_editor}, action) : state;
    })
  };
}
