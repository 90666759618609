import { withOnline } from 'components/helpers/online-helper';
import Map, {
  Marker, position, markerIconURL, present, US_CENTER
} from 'components/helpers/map';

class CoordinateSelection extends React.Component {
  state = {
    center: present(this.props.location) ? position(this.props.location) : US_CENTER,
  }

  setLocation = ({latLng}) => {
    this.props.onChange('latitude', latLng.lat());
    this.props.onChange('longitude', latLng.lng());
  }

  render() {
    const {online, location, readonly} = this.props;
    if (!online) {
      return (
        <div className="flex-column padding">
          <p className="error">You must be online to view map.</p>
        </div>
      );
    }
    const {center} = this.state;
    return (
      <Map
        center={center}
        mapContainerStyle={{maxHeight: "50vh", height: "400px"}}
        onClick={(e) => (present(location) || readonly) ? _.noop : this.setLocation(e)}
        zoom={present(location) ? 10 : 4}
      >
        {present(location) && (
          <Marker draggable={!readonly}
            position={position(location)}
            icon={markerIconURL(readonly ? 'PUBLIC' : 'OWN')}
            onDragEnd={this.setLocation}
          />
        )}
      </Map>
    );
  }
}

export default withOnline(CoordinateSelection);
