import store from 'store';
import API from 'API';
import Repository from 'repository';
import ServerActions from 'actions/ServerActions';

export default {
  getProjects() {
    API.getProjects();
  },

  addProject(project) {
    API.addProject({settings: {}, data: {}, ...project});
    store.dispatch({type: 'PROJECT_SUBMITTED'});
  },

  getProject(project_id) {
    API.getProject(project_id);
    Repository.persistProject();
  },

  deleteProject(project) {
    if (project.createdOfflineAt) {
      store.dispatch({type: 'DELETED_PROJECT', project});
    } else {
      API.deleteProject(project.id);
    }
  },


  setCurrentProject(id) {
    store.dispatch({type: 'SET_CURRENT_PROJECT', id});
  },

  updateProjectSettings(projectData, project) {
    if (project.createdOfflineAt) {
      ServerActions.receivedProject({...project, ...projectData});
    } else {
      API.updateProject(projectData);
    }
    Repository.persistProject();
  },

  clearErrors() {
    store.dispatch({type: 'CLEAR_ERRORS'});
  },

  getPublicProjects() {
    API.getPublicProjects();
  },

  reset() {
    store.dispatch({type: 'RESET'});
  }
};
