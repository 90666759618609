import {rootReducer} from 'reducers/index';

function dualFilter(segments, {includeDualCutter}) {
  if (includeDualCutter) return segments;
  return _.filter(segments, ({helices}) => !_.find(helices, {type: 'dual'}));
}

function filterLengths({minlength=0, maxlength}, segments) {
  maxlength = maxlength || 1e6;
  return _.filter(segments, (segment) => {
    return segment.length >= minlength && segment.length <= maxlength;
  });
}

function pickClean(obj, ...propNames) {
  return _.pickBy(_.pick(obj, propNames), _.identity);
}

export function filterLeadList(segments, options={}) {
  options = {...options, length: options.length ? parseInt(options.length, 10) : null};
  const leads = _.filter(segments, {lead_extension: 'Lead', ...pickClean(options, 'diameter', 'pitch', 'coupling', 'wall')});
  return dualFilter(filterLengths({maxlength: options.pileLength*12}, leads), options);
}

const getCurrentProject = (state) => {
  const projectId = _.get(state, 'projectStatus.projectID');
  const projects = _.get(state, 'projects');
  return _.find(projects, {id: projectId}) || _.find(projects, {id: parseInt(projectId, 10)});;
}

function compatible(lead={}, pileOptions={}) {
  return (
    lead.diameter == pileOptions.diameter &&
    lead.pitch == pileOptions.pitch &&
    lead.coupling == pileOptions.coupling &&
    lead.wall == pileOptions.wall &&
    lead.length/12 <= pileOptions.pileLength
  );
}

function leadOptions(leadList=[]) {
  return _.uniqBy(_.orderBy(leadList, 'length'), ({helixConfig}) => _.join(helixConfig));
}

function selectCompatable(leadList=[], pileOptions={}) {
  return _.find(leadOptions(leadList), ({helixConfig}) => _.join(helixConfig) == _.join(pileOptions.helixConfig));
}
export function setLead(lead, project) {
  const {pileLength} = _.get(project, 'settings.pile', {});
  return {type: 'SET_PILE', lead, pileLength};
}

export default function makePileConsistent(state) {
  const project = getCurrentProject(state);
  if (!_.get(project, 'owned') && !_.get(state, 'user.userInfo.global_editor')) return state;

  const [lead, ...extensions] = _.get(project, 'data.pileSegments', []);
  const pileOptions = _.get(project, 'settings.pile');
  if (compatible(lead, pileOptions)) return state;

  const leadList = filterLeadList(_.get(state, 'products.segmentList'), pileOptions);
  const newLead = selectCompatable(leadList, pileOptions);
  if (newLead) {
    return rootReducer(state, setLead(newLead, project));
  } else {
    return rootReducer(state, {type: 'RESET_PILE_SEGMENTS'});
  }
}
