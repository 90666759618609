const STRAIGHT_TOP = (diameter) => ["l", diameter, 0];
const BROKEN_TOP = (diameter) => [
  "c", diameter/10, -diameter/6, diameter/2 - diameter/10, -diameter/6, diameter/2, 0,
  "s", diameter/2 - diameter/10, diameter/6, diameter/2, 0
];

export default function ShaftGraphic({
  diameter=6,
  positionFromTop=0,
  shaftLength:shaftDepth=5,
  lead_extension='Extension',
  aboveGrade=0,
  factorY
}) {
  if (lead_extension !== 'Lead') return null;
  const shaftTop = Math.max(-aboveGrade || 0, -1.5);
  const shaftLength = shaftDepth - shaftTop;
  const topPath = shaftTop < -1 ? BROKEN_TOP : STRAIGHT_TOP;
  return (
    <path
      className="shaft-segment"
      d={[
        "M", -diameter/2, shaftTop*factorY,
        ...topPath(diameter),
        "l", 0, (positionFromTop + shaftLength)*factorY,
        "l", -diameter, diameter,
        "l", 0, -((positionFromTop + shaftLength)*factorY + diameter)
      ].join(" ")} />
  );
}
