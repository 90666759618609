import ReactSwipe from 'react-swipe';

export default class SwipeCarousel extends React.Component {
  onChange = (index) => {
    index = index % this.props.options.length;
    this.props.onChange(this.props.options[index].value);
  }

  next = () => this.refs.selectSwipe.swipe.next()

  prev = () => this.refs.selectSwipe.swipe.prev()

  render() {
    const {options, value} = this.props;
    const startSlide = _.findIndex(options, {value});
    return (
      <div className="select-menu">
        <i className="prev-item" onClick={this.prev} />
        <i className="next-item" onClick={this.next} />
        <ReactSwipe key={JSON.stringify(options)}
          ref="selectSwipe"
          swipeOptions={{callback: this.onChange, startSlide}}
        >
          {options.map((option) => (
            <div key={option.value} value={option.value} className="option">
              {option.title}
            </div>
          ))}
        </ReactSwipe>
      </div>
    );
  }
}
