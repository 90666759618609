import { createSelector } from 'reselect';

import {
  BLOW_COUNT_INTERVAL_E, HELIX_CAP_COEF, SHAFT_CAP_COEF, SHAFT_FRICTION_COEF, SHAFT_FRICTION_REDUCTION, LEAD_GROUT_STRENGTH_N70
} from 'lib/constants';
import SoilLabels from 'config/soil-labels';
import PartList from './part-list';

function safe(value, safe_value) {
  return isNaN(value) ? (safe_value || 1) : value;
}

const UNDEFINED_SOIL = {value: 'undefined', label: 'Undefined', soilCoef: 0, coneCoef: 0};

export const lookupSoilAtDepth = (boundaries, soilList) => {
  return (depth) =>{
    const layerIndex = _.sortedIndex(boundaries, depth);
    return _.find(SoilLabels, {'value': _.get(soilList, [layerIndex, 'name'])}) || UNDEFINED_SOIL;
  };
};

export const testUnitConversion = (oldUnit, newUnit, boundaries, soilList) => {
  return (c, i) => {
    const soil = lookupSoilAtDepth(boundaries, soilList)(i * BLOW_COUNT_INTERVAL_E);
    return _.round(newUnit.toDisplay(oldUnit.toBase(c, soil), soil), newUnit.precision);
  }
};

export const requiredInstallTorque = ({data: {pileSegments, loads: {compression, tension}, safetyFactor}, settings: {forceUnit}}) => {
  const lead = _.find(pileSegments, {lead_extension: 'Lead'});
  if (!lead) return null;
  return Math.max(tension, compression) * safetyFactor / lead.torque;
};

export const calculateSTDData = (meanSeries, stdSeries, sigma=1) => {
  return _.zip(meanSeries, stdSeries).map(([mean=0, std=0]) => {
    return Math.max(mean + (std * sigma), 0);
  });
};

const getProject = (project) => project;
const getSigma = (_project, sigma) => sigma || 0;
const getGraphFunction = (_project, _sigma, graphFunction) => graphFunction;

const getData = (project) => project.data;
const getSettings = (project) => project.settings;
const getDepth = (_project, _sigma, depth) => depth;
const getBlowCounts = createSelector(
  getData, getSigma,
  (data, sigma) => calculateSTDData(data.blowCounts, data.blowCountsSD, sigma)
);
const getSafetyFactor = ({data}) => data.safetyFactor;
const getPileSegments = createSelector(getData, (data) => data.pileSegments);
const getShafts = createSelector(getPileSegments, (pileSegments) => PartList.shafts(pileSegments));
const getHelices = createSelector(getPileSegments, (pileSegments) => PartList.helices(pileSegments));
const getPostGrout = createSelector(getSettings, (s) => s.groutedShaft ? s.postGroutLead : false);
const getAboveGrade = createSelector(getSettings, (s) => s.aboveGrade);

const getTestUnit = createSelector(getSettings, (settings) => settings.testUnit);
const getLengthUnit = createSelector(getSettings, (settings) => settings.lengthUnit);
const getPileLength = createSelector(getData, (data) => PartList.totalLength(data.pileSegments));
const getBatterAngleAdjustment = createSelector(
  getSettings,
  (settings) => {
    const angle = settings.batteredPile ? settings.batterAngle || 0 : 0;
    return Math.cos((angle * Math.PI) / 180);
  }
);

const getGroutDiameter = createSelector(
  getSettings,
  (settings) => {
  return settings.groutedShaft ? settings.groutDiameter : false}
);

const getRed = createSelector(
  getSettings,
  ({includeShaftFriction, reduceShaftFriction, groutedShaft}) => {
    if (groutedShaft) return 1;
    const shaftFriction = includeShaftFriction ? SHAFT_FRICTION_COEF : 0;
    return reduceShaftFriction ? (shaftFriction * SHAFT_FRICTION_REDUCTION) : shaftFriction;
  }
);

const getSoilAtDepth = createSelector(
  getData, getDepth, getBatterAngleAdjustment,
  (data, depth, batterAngleAdjustment) => {
    const {boundaries, soilTypes} = data;
    try {
      return lookupSoilAtDepth(boundaries, soilTypes)(depth * batterAngleAdjustment);
    } catch (e) {
      console.warn("Failed to find soil type at provided depth", data, depth * batterAngleAdjustment)
      return _.first(SoilLabels);
    }
  }, {
    maxSize: 100
  }
)
const getSoilCoefAtDepth = createSelector(
  getSoilAtDepth,
  (soilAtDepth) => soilAtDepth.soilCoef
);
const getShaftSoilCoefAtDepth = createSelector(
  getSoilAtDepth,
  getSettings,
  (soilAtDepth, settings) => {
    if (soilAtDepth.value === 'sensitive' && !settings.includeFillSensitive) return 0;
    if (soilAtDepth.value === 'bedrock' && !settings.includeBedrock) return 0;
    return soilAtDepth.soilCoef;
  }
);
const getBlowCountAtDepth = createSelector(
  getBlowCounts, getDepth, getSoilAtDepth, getBatterAngleAdjustment, getTestUnit,
  (blowCounts, depth, soilAtDepth, batterAngleAdjustment, testUnit) => {
    const blowCountIndex = Math.max(depth * batterAngleAdjustment / BLOW_COUNT_INTERVAL_E, 0);
    const blowCount1 = blowCounts[Math.floor(blowCountIndex)];
    const blowCount2 = blowCounts[Math.ceil(blowCountIndex)];
    const interpolation = (blowCount2 - blowCount1) * (blowCountIndex % 1);
    const rawBlowCount = blowCount1 + interpolation;
    return testUnit.toBase(rawBlowCount, soilAtDepth);
  }, {
    maxSize: 100
  }
)

const getHelix = (project, sigma, depth, shaftOrHelix) => shaftOrHelix;
const getShaft = getHelix;

const getCapFromHelix = createSelector(
  getDepth, getHelix, getPostGrout, getAboveGrade, getBlowCountAtDepth, getSoilCoefAtDepth,
  (depth, helix, postGroutLead=false, aboveGrade, blowCountAtDepth, soilCoefAtDepth) => {
    if (depth <= Math.max(0, -aboveGrade)) {
      return 0;
    } else {
      const soilStrength = Math.max(blowCountAtDepth, postGroutLead ? LEAD_GROUT_STRENGTH_N70 : 0);
      return HELIX_CAP_COEF * soilCoefAtDepth * soilStrength * helix.area;
    }
  }, {
    maxSize: 200
  }
);

const getAverageSoilFactor = createSelector(
  getProject, getSigma, getDepth, getShaft, getShaftSoilCoefAtDepth, getBlowCountAtDepth,
  (project, sigma, depth, shaft, soilCoefAtDepth, blowCountAtDepth) => {
    return _.mean([
      soilCoefAtDepth * blowCountAtDepth,
      ..._.times(shaft.shaftLength, (n) => (
        getShaftSoilCoefAtDepth(project, sigma, depth + n)) * getBlowCountAtDepth(project, sigma, depth + n)
      )
    ]);
  }, {
    maxSize: 200
  }
);

const getCapFromShaft = createSelector(
  getDepth, getShaft, getRed, getGroutDiameter, getAboveGrade, getAverageSoilFactor,
  (depth, shaft, red, groutDiameter, aboveGrade, averageSoilFactor) => {
    if (depth <= Math.max(0, -aboveGrade)) {
      return 0;
    } else if (!_.isEmpty(shaft.helices)) {
      return 0;
    } else {
      const diameter = groutDiameter || shaft.diameter;
      return SHAFT_CAP_COEF * averageSoilFactor * diameter * shaft.shaftLength * red;
    }
  }, {
    maxSize: 100
  }
);

const getShaftCap = createSelector(
  getProject, getSigma, getDepth, getBlowCounts, getShafts, getRed, getGroutDiameter, getAboveGrade,
  (project, sigma, depth, blowCounts, shafts, red, groutDiameter, aboveGrade) => {
    return shafts.map((shaft) => {
      const factionOfShaftWithinDepth = _.clamp((depth - shaft.positionFromTop) / shaft.shaftLength, 0, 1);
      return factionOfShaftWithinDepth * getCapFromShaft(project, sigma, shaft.positionFromTop, shaft);
    }).reduce(_.add, 0);
  }, {
    maxSize: 120
  }
);
const getHelixCap = createSelector(
  getProject, getSigma, getDepth, getBlowCounts, getHelices, getPostGrout, getAboveGrade,
  (project, sigma, depth, blowCounts, helices, postGroutLead, aboveGrade) => {
    return helices.map((helix) => {
      const helixDepth = depth - helix.positionFromBottom;
      return getCapFromHelix(project, sigma, helixDepth, helix);
    }).reduce(_.add, 0);
  }, {
    maxSize: 120
  }
);

const totalCapacityAtDepth = createSelector(
  getHelixCap, getShaftCap, getSafetyFactor,
  (helixCap, shaftCap, safetyFactor) => safe((helixCap + shaftCap) / safetyFactor), {
    maxSize: 6
  }
);

const getMaxDepth = createSelector(
  getLengthUnit, getPileLength,
  (lengthUnit, pileLength) => lengthUnit.toDisplay(pileLength)
);
const getInterval = createSelector(getSettings, (settings) => settings.interval);

const buildGraphData = createSelector(
  getProject, getSigma, getGraphFunction, getMaxDepth, getInterval, getLengthUnit,
  (project, sigma, graphFunction, maxDepth, interval, lengthUnit) => {
    const dataSet = [];
    for (let depth = 0; depth < (maxDepth + interval/2); depth += interval) {
      dataSet.push({y: depth, x: graphFunction(project, sigma, lengthUnit.toBase(depth))});
    }
    return dataSet;
  }
)

export const capacities = createSelector(
  getProject, getSigma,
  (project, sigma) => buildGraphData(project, sigma, totalCapacityAtDepth)
);

const getBaseCapacity = createSelector(
  getData, getSettings, getSigma, getDepth,
  (data, settings, sigma, depth) => {
    return totalCapacityAtDepth({
      data: {...data, safetyFactor: 1},
      settings: {...settings, includeShaftFriction: false, groutedShaft: false}
    }, sigma, depth);
  }
);
const getMaxTorque = createSelector(
  getPileSegments,
  (pileSegments) => PartList.maxTorque(pileSegments)
);

const torque = createSelector(
  getBaseCapacity, getMaxTorque,
  (baseCapacity, maxTorque) => safe(baseCapacity / maxTorque)
);
export const torques = createSelector(
  getProject, getSigma,
  (project, sigma) => buildGraphData(project, sigma, torque)
);
