import { showPopup } from 'lib/ionic-utils';

export const displayDiameterError = (context, pileDiameter, groutDiameter, data) => {
  const errorMessage = "The shaft you have selected is larger than your grout diameter.";

  if ((data.pileSegments.length >= 1) && pileDiameter >= groutDiameter) {
    return () => showPopup(context, <p>{errorMessage}</p>);
  }
}

const DISCONTINUED = <p>This pile type has been discontinued.</p>;
export const displayDiscontinuedWarning = (context) => () => showPopup(context, DISCONTINUED);
