import { MAX_LEVEL } from 'lib/constants';
import { DATA } from 'reducers/projects/defaults';
import PartList from 'lib/part-list';

function safetyFactor(state=DATA.safetyFactor, action) {
  switch(action.type) {
    case 'RECEIVED_NEW_SAFETY_FACTOR':
      return action.factor;
    default:
      return state;
  }
}

function pileCount(state=DATA.pileCount, action) {
  switch(action.type) {
    case 'RECEIVED_NEW_PILE_COUNT':
      return action.amount;
    default:
      return state;
  }
}

function loads(state=DATA.loads, action) {
  switch(action.type) {
    case 'RECEIVED_NEW_LOAD':
      return {...state, ...action.load};
    default:
      return state;
  }
}

function blowCounts(state=DATA.blowCounts, action) {
  switch(action.type) {
    case 'RECEIVED_NEW_BLOW_COUNTS':
      return action.blowCounts;
    case 'SOIL_RESET':
      return DATA.blowCounts;
    default:
      return state;
  }
}

function blowCountsSD(state=DATA.blowCountsSD, action) {
  switch(action.type) {
    case 'SET_BLOW_COUNT_SD':
      return action.blowCountsSD;
    case 'SOIL_RESET':
      return DATA.blowCountsSD;
    default:
      return state;
  }
}

function boundaries(state=DATA.boundaries, {boundaries, ...action}) {
  switch(action.type) {
    case 'SET_BOUNDARIES':
      return boundaries;
    case 'SOIL_RESET':
      return DATA.boundaries;
    default:
      return state;
  }
}

function soilTypes(state=DATA.soilTypes, action) {
  switch(action.type) {
    case 'SET_SOIL_TYPES':
      return action.soils;
    case 'SOIL_RESET':
      return DATA.soilTypes;
    default:
      return state;
  }
}

function pileSegments(initialState, action) {
  const state = initialState || [];

  switch(action.type) {
    case 'RESET_PILE_SEGMENTS':
      return [];
    case 'REMOVE_EXTENSION':
      return _.reject(state, {id: action.id});
    case 'ADD_SEGMENT':
      const id = _.maxBy(state, 'id').id + 1;
      return [...state, {...action.selectedSegment, id}];
    case 'SET_PILE':
      const extensionLength = action.pileLength - (action.lead.length / 12);
      const extension = PartList.extensionFromLead(action.lead);
      return [
        {...action.lead, id: 1},
        ...PartList.extensionsForLength(extension, extensionLength)
      ];
    default:
      return state;
  }
}

function bulkSoil(state=DATA.bulkSoil, action) {
  switch(action.type) {
    case 'SET_BULK_SOIL':
      return action.bulkSoil;
    case 'SET_SOIL_TYPES':
    case 'SET_BOUNDARIES':
    case 'RECEIVED_NEW_BLOW_COUNTS':
    case 'SOIL_RESET':
      return DATA.bulkSoil;
    default:
      return state;
  }
}

export default function data(state=DATA, action) {
  return {
    ...state,
    safetyFactor: safetyFactor(state.safetyFactor, action),
    loads: loads(state.loads, action),
    blowCounts: blowCounts(state.blowCounts, action),
    blowCountsSD: blowCountsSD(state.blowCountsSD, action),
    boundaries: boundaries(state.boundaries, action),
    soilTypes: soilTypes(state.soilTypes, action),
    pileSegments: pileSegments(state.pileSegments, action),
    pileCount: pileCount(state.pileCount, action),
    bulkSoil: bulkSoil(state.bulkSoil, action)
  };
}
