import { IonIcon } from 'reactionic';
import SoilTestUnits from 'config/soil-test-units';
import GenericOption from 'components/helpers/GenericOption';
import ProjectSettingsActions from 'actions/ProjectSettingsActions';

export default function SoilTestUnitSelector({selected, system, help}) {
  const changeTestUnits = (event) => ProjectSettingsActions.setTestUnit(event.target.value);

  const selectedValue = selected[system].copyOf || selected.value;

  return (
    <label className="item item-input item-select">
      <span className="input-label" onClick={help}>
        Soil Test Units <IonIcon icon="help-circled"/>:
      </span>
      <select onChange={changeTestUnits} value={selectedValue}>
        {_.map(SoilTestUnits, (unit) => {
          if (unit[system].copyOf) return null;
          const label = `${unit.label} (${unit[system].unit})`;
          return <GenericOption key={unit.value} value={unit.value} label={label}/>;
        })}
      </select>
    </label>
  );
}
