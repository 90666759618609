export default {
  "single": {
    "6": 0.2,
    "8": 0.35,
    "10": 0.55,
    "12": 0.79,
    "14": 1.07,
    "16": 1.4,
    "18": 1.77,
    "20": 2.18,
    "24": 3.14,
    "30": 4.91,
    "32": 5.59,
    "36": 7.07,
    "42": 9.62
  },
  "dual": {
    "6": 0.17,
    "8": 0.3,
    "10": 0.46,
    "12": 0.67,
    "14": 0.91,
    "16": 1.18,
    "18": 1.5,
    "20": 1.85,
    "24": 2.66,
    "30": 4.16,
    "32": 4.73,
    "36": 5.99,
    "42": 8.15
  }
}
