import store from 'store';
import { withOnline } from 'components/helpers/online-helper';
import makeCancelable from 'helpers/cancelable-promise';
import ProjectActions from 'actions/ProjectActions';

import { IonItemToggle } from 'reactionic';
import { Geolocation } from '@capacitor/geolocation';
import Map, {
  Marker, InfoWindow, position, markerIconFor, present, US_CENTER
} from 'components/helpers/map';

import { useGoogleMap } from '@react-google-maps/api'

const allProjects = _.memoize(({publicProjects=[], projects=[]}) => (
  _.uniqBy([...publicProjects, ...projects], 'id')
));
const withCoords = _.memoize((projectList) => _.filter(projectList, present));

class GlobalProjects extends React.Component {
  state = {
    projectList: allProjects(store.getState()),
    center: US_CENTER,
    displayedProject: null,
    showUnpublished: false,
    dirty: false,
  }

  map = React.createRef()

  componentDidMount() {
    this.unsubscribe = store.subscribe(() => {
      this.setState({projectList: allProjects(store.getState())});
    });

    if (!this.props.online) return;
    this.loadProjects();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.online && this.props.online) this.loadProjects();
  }

  componentWillUnmount() {
    this.unsubscribe();
    this._geolocate && this._geolocate.cancel();
  }

  loadProjects = () => {
    ProjectActions.getPublicProjects();
    ProjectActions.getProjects();
    if (Geolocation) {
      this._geolocate = makeCancelable(Geolocation.getCurrentPosition());
      this._geolocate.promise.then((position) => {
        if (!this.state.dirty) this.setState({
          center: {lat: position.coords.latitude, lng: position.coords.longitude}
        });
      }).catch(_.noop);
    }
  }

  selectMarker = (id, position) => {
    this.setState(({center}) => ({displayedProject: id}));
    if (position) this.map.current.panTo(position);
  }

  setShowUnpublished = (showUnpublished) => {
    this.setState({showUnpublished});
  }

  setCenter = () => {
    const center = this.map.current?.getCenter();
    if (center) this.setState({center, dirty: true});
  }

  render() {
    const {online} = this.props;
    if (!online) {
      return (
        <div className="flex-column padding">
          <p className="error">You must be online to view global projects.</p>
        </div>
      );
    }
    const {loadingLocation, center, projectList, displayedProject, showUnpublished} = this.state;
    const {is_admin} = _.get(store.getState(), 'user.userInfo');
    const verifiedProjectList = _.reject(projectList, {verified: false, owned: false});
    const filteredProjectList = showUnpublished ? verifiedProjectList : _.reject(verifiedProjectList, {published: false, owned: false});
    return (
      <div className="flex-column">
        <Map
          onLoad={(map) => this.map.current = map }
          mapContainerClassName="maps-container"
          zoom={4}
          center={center}
          onCenterChanged={this.setCenter}
        >
          {withCoords(filteredProjectList).map((project) => (
            <Marker key={project.id}
              position={position(project)}
              icon={markerIconFor(project)}
              onClick={() => this.selectMarker(project.id, position(project))}
            >
              {project.id == displayedProject && (
                <InfoWindow onCloseClick={() => this.selectMarker(null)}>
                  <div>
                    <div><a href={`#/projects/${project.id}/`}>{project.name}</a></div>
                    {!project.owned && !project.published && "(unpublished)"}
                  </div>
                </InfoWindow>
              )}
            </Marker>
          ))}
        </Map>
        {is_admin && (
          <IonItemToggle
            color="positive"
            label="Show unpublished projects"
            checked={showUnpublished}
            handleChange={this.setShowUnpublished}/>
        )}
      </div>
    );
  }
}

export default withOnline(GlobalProjects);
