import store from 'store';

export function getAuthToken() {
  return _.get(store.getState(), 'user.authentication.token');
}

export function authHeaders() {
  return {
    headers: {
      Authorization: 'Bearer ' + getAuthToken()
    }
  };
};

export function createParams(options) {
  return '?' +
    Object.keys(options).map(function(key) {
      if (options[key]) {
        return encodeURIComponent(key) + '=' + encodeURIComponent(options[key]);
      }
    }).join('&');
};

export function onSuccess(success, fail=_.noop) {
  return (error, response) => {
    store.dispatch({type: 'SET_NETWORK_STATUS', network_status: response.statusCode > 0});
    if (error || response.statusCode >= 400) {
      console.error('API Error:', error, response);
      if (response.statusCode == 401) {
        store.dispatch({type: 'LOGOUT'});
      }
      return fail(error || response.body, response.statusCode);
    }
    return success(response.body);
  };
};

function tryJSON(body) {
  try {
    return JSON.parse(body);
  } catch (e) {
    return body;
  }
}

export function JSONonSuccess(success, fail=_.noop) {
  const parse = (callback) => (body) => callback(tryJSON(body));
  return onSuccess(parse(success), parse(fail));
}
