import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers/index';

const store = createStore(
  rootReducer,
  applyMiddleware(thunk)
);

global.setNetworkStatus = (network_status) => store.dispatch({type: "SET_NETWORK_STATUS", network_status})
export default store;

export function loggedIn() {
  return !!_.get(store.getState(), 'user.authentication.token');
}
