import { projectInfo } from 'config/terms-conditions';
import { IonContent, IonNavBar, IonButton } from 'reactionic';

const BackButton = (
  <IonButton
    icon="ion-chevron-left"
    link="/projects/new"
    iconPosition="left"
    title="back"
    color="light"
    type="clear"
  >
    back
  </IonButton>
);

export default function ProjectInfo() {
  return (
    <div>
      <IonNavBar
        customClasses="bar-dark"
        title="Project Information"
        leftButton={BackButton}/>
      <IonContent customClasses="has-header">
        <div className="padding">
          <p>{projectInfo}</p>
        </div>
      </IonContent>
    </div>
  );
}
