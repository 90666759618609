import store from 'store';
import Repository from 'repository';

export default {
  setBlowCounts(blowCounts) {
    store.dispatch({
      type: 'RECEIVED_NEW_BLOW_COUNTS',
      blowCounts
    });
    Repository.persistProject();
  }
}
