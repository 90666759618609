import store from 'store';
import UserStore from 'stores/UserStore';
import torqueUnitFor from 'config/torque-units';
import SoilList from 'config/soil-labels';
import maxGraphDepth from 'lib/graph-depth';
import { requiredInstallTorque } from 'lib/capacity-calculations';
import { toSignificant } from 'lib/number';
import PartList from 'lib/part-list';
import { displayForUnit, smallUnitDisplay } from 'helpers/unit-display';

import StaticMap from 'components/helpers/static-map';
import SoilLog from 'components/project/soil-log/SoilLog';
import PileGraph from 'components/project/pile-visualizer/PileGraph';
import CapacityCalcResults from './report/CapacityCalcResults';
import Page from './report/Page';

import LOGO from 'images/magnum-geoapp-logo.png';
import CALCULATIONS_PAGE from 'images/calculations.png';

const CURRENT_YEAR = new Date().getFullYear();

const cutSheetPresent = ({prefix, pitch}) => {
  const products = _.get(store.getState(), "products.productTypeList");
  const product = _.find(products, {prefix});
  return !!(product && product.cut_sheets[pitch]);
}

export default function Report({project}) {
  const {settings, data} = project;
  const user = UserStore.getInfo();
  const torqueUnit = torqueUnitFor(settings.lengthUnit);
  const lead = _.first(data.pileSegments) || {};
  const forceUnitDisplay = displayForUnit(settings.forceUnit);
  const smallLengthUnitDisplay = displayForUnit(settings.smallLengthUnit);
  const lengthUnitDisplay = displayForUnit(settings.lengthUnit);
  const maxDepth = maxGraphDepth(project);
  const hasCutSheet = cutSheetPresent(settings.pile);

  return (
    <div>
      <Page number="1">
        <div className="uk-grid">
          <div className="uk-width-1-2">
            <h2>Prepared by</h2>
            <table className="uk-table uk-table-condensed"><tbody>
              <tr>
                <th>Designer</th>
                <td>{ user.name }</td>
              </tr>
              <tr>
                <th>Company</th>
                <td>{ user.company }</td>
              </tr>
              <tr>
                <th>Address</th>
                <td>{ user.address }<br/>{ user.city }, { user.state }  { user.zipcode }</td>
              </tr>
              <tr>
                <th>Country</th>
                <td>{ user.country }</td>
              </tr>
              <tr>
                <th>Phone</th>
                <td>{ user.phone }</td>
              </tr>
              <tr>
                <th>Email</th>
                <td>{ user.email }</td>
              </tr>
            </tbody></table>
          </div>
          <div className="uk-width-1-2">
            <h2>Prepared for</h2>
            <table className="uk-table uk-table-condensed"><tbody>
              <tr>
                <th>Client</th>
                <td>{ project.client }</td>
              </tr>
              <tr>
                <th>Project Name</th>
                <td>{ project.name }</td>
              </tr>
              <tr>
                <th>Address</th>
                <td>{ project.address }<br/>{ project.city }, { project.state }  { project.zipcode }</td>
              </tr>
            </tbody></table>
          </div>
          <div className="uk-width-1-2">
            {!!project.latitude && <h2>Project Location</h2>}
            <StaticMap
              height="250"
              width="380"
              zoom="9"
              latitude={project.latitude}
              longitude={project.longitude}
            />
          </div>
        </div>
        <div style={{width: '50%', margin: '1em auto'}}>
          <h2>Table of Contents</h2>
          <table className="uk-table uk-table-condensed">
            <thead>
              <tr><th>Contents</th><th>Page</th></tr>
            </thead>
            <tbody>
              <tr><td>Required Capacity</td><td>MP1</td></tr>
              <tr><td>Project Location</td><td>MP1</td></tr>
              <tr><td>Approximate Pile Locations</td><td>MP2</td></tr>
              <tr><td>Introduction</td><td>MP2</td></tr>
              <tr><td>Design Options</td><td>MP2</td></tr>
              <tr><td>Engineering Logic</td><td>MP3</td></tr>
              <tr><td>Baseline Soil Profile</td><td>MP4</td></tr>
              <tr><td>Pile Configuration</td><td>MP5</td></tr>
              <tr><td>Graphical Results</td><td>MP6</td></tr>
              {hasCutSheet && <>
                <tr><td>Helical Pile Cut Sheet</td><td>MP7</td></tr>
              </>}
              <tr><td>Limitations and Conditions of Use</td><td>MP{hasCutSheet ? '8' : '7'}</td></tr>
            </tbody>
          </table>
        </div>
      </Page>
      <Page number="2">
        {!!project.latitude && <h2>Approximate Pile Locations</h2>}
        <StaticMap
          height="350"
          width="850"
          zoom="14"
          latitude={project.latitude}
          longitude={project.longitude}
          maptype="satellite"/>
        <h2>Introduction</h2>
        <p>
          The following calculations were prepared using Magnum&reg; GeoApp&reg; software.  This software is offered
          exclusively for use by design professionals and construction estimators for sizing helical piles manufactured
          by Magnum Piering, Inc.  The software utilizes the individual bearing method of helical pile bearing/pullout
          capacity estimation and theoretical capacity:torque ratios as described by Perko (2009), <em>Helical Piles:
          A Practical Guide to Design and Installation</em>, Wiley, New York.
        </p>
        <div className="uk-grid">
          <div className="uk-width-1-2">
            <h2>Design Options</h2>
            <table className="uk-table uk-table-condensed"><tbody>
              <tr>
                <th>Soil Strength Test Units</th>
                <td>{ settings.testUnit.label }</td>
              </tr>
              <tr>
                <th>Project Units</th>
                <td>{ settings.forceUnit.label } - { settings.lengthUnit.label }</td>
              </tr>
              <tr>
                <th>Factor of Safety</th>
                <td>{ data.safetyFactor }</td>
              </tr>
              <tr>
                <th>Include Shaft Friction:</th>
                <td>{ settings.includeShaftFriction ? 'YES' : 'NO' }</td>
              </tr>
              <tr>
                <th>Reduce Shaft Friction</th>
                <td>{ settings.reduceShaftFriction ? 'YES' : 'NO' }</td>
              </tr>
              <tr>
                <th>Grouted Shaft</th>
                <td>{ settings.groutedShaft ? 'YES' : 'NO' }</td>
              </tr>
              <tr>
                <th>Grout Diameter</th>
                <td>
                  { settings.groutedShaft ? (
                    settings.groutDiameter ? smallLengthUnitDisplay(settings.groutDiameter) : 'not specified'
                  ) : 'n/a' }
                </td>
              </tr>
            </tbody></table>
          </div>
          <div className="uk-width-1-2">
            <h2>Design Loads</h2>
            <table className="uk-table uk-table-condensed"><tbody>
              {data.loads.compression > 0 && (
                <tr>
                  <th>Compression</th>
                  <td>{forceUnitDisplay(data.loads.compression)}</td>
                </tr>
              )}
              {data.loads.tension > 0 && (
                <tr>
                  <th>Tension</th>
                  <td>{forceUnitDisplay(data.loads.tension)}</td>
                </tr>
              )}
              {requiredInstallTorque(project) > 0 && (
                <tr>
                  <th>Required Torque</th>
                  <td>{ settings.groutedShaft ? 'n/a' : toSignificant(torqueUnit.toDisplay(requiredInstallTorque(project)), 3).toLocaleString() } { !settings.groutedShaft && torqueUnit.abbreviation }</td>
                </tr>
              )}
              {data.loads.lateral && (
                <tr>
                  <th>Lateral</th>
                  <td>{forceUnitDisplay(data.loads.lateral)}</td>
                </tr>
              )}
            </tbody></table>
          </div>
        </div>
      </Page>
      <Page number="3">
        <h2>Engineering Logic</h2>
        <img src={process.env.CALCULATIONS_IMAGE || (window.location.origin + CALCULATIONS_PAGE)} />
      </Page>
      <Page number="4">
        <h2>Baseline Soil Profile</h2>
        <div className="uk-grid">
          <div className="uk-width-1-3">
            <div style={ {maxHeight: '8in', overflow: 'hidden'} }>
              <SoilLog project={project} maxDim={{width: 300, height: 800}} readonly={true} />
            </div>
          </div>
          <div className="uk-width-2-3">
            <div className="uk-panel uk-panel-box">
              <h3>Legend</h3>
              <table className="uk-table">
                <thead>
                  <tr>
                    <th>Pattern</th>
                    <th>Description</th>
                    <th>Soil Coef</th>
                    <th>Cone Coef</th>
                  </tr>
                </thead>
                <tbody>
                  {SoilList.map((soil) => (
                    <tr key={soil.value}>
                      <td><div className={"swatch " + soil.value}/></td>
                      <td>{soil.label}</td>
                      <td>{soil.soilCoef}</td>
                      <td>{soil.coneCoef}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Page>
      <Page number="5">
        <div className="uk-grid uk-grid-small">
          <div className="uk-width-1-5">
            <h2>Pile</h2>
            <div style={ {maxHeight: '8in', overflow: 'hidden'} }>
              <PileGraph {...data} {...settings}
                settings={settings}
                maxDepth={maxDepth}
                shafts={PartList.shafts(data.pileSegments)}
                helices={PartList.helices(data.pileSegments)}
                maxDim={{width: 300, height: 800}}/>
            </div>
          </div>
          <div className="uk-width-4-5">
            <h2>Configuration</h2>
            <table className="uk-table uk-table-condensed">
              <tbody>
                <tr><th>Shaft</th><td>{settings.pile.prefix}</td></tr>
                <tr><th>Shaft Diameter</th><td>{smallLengthUnitDisplay(lead.diameter)}</td></tr>
                <tr><th>Shaft Wall Thickness</th><td>{smallLengthUnitDisplay(lead.wall, 2)}</td></tr>
                <tr><th>Shaft Coupling</th><td>{lead.coupling}</td></tr>
                <tr><th>Coating</th><td>{settings.pile.coating}</td></tr>
                <tr><th>Pitch</th><td>{smallLengthUnitDisplay(settings.pile.pitch)}</td></tr>
                <tr><th>Number of Piles</th><td>{data.pileCount}</td></tr>
                <tr>
                  <th>Length</th>
                  <td>
                    {lengthUnitDisplay(settings.pile.pileLength + settings.aboveGrade)}&nbsp;
                    {settings.aboveGrade != 0 && (
                      <em>({lengthUnitDisplay(Math.abs(settings.aboveGrade))} {settings.aboveGrade > 0 ? "above" : "below" } grade)</em>
                    )}
                  </td>
                </tr>
                {settings.batteredPile && !!settings.batterAngle && (
                  <tr><th>Batter Angle</th><td>{settings.batterAngle}°</td></tr>
                )}
                <tr>
                  <th>Helix Config</th>
                  <td>
                    {settings.pile.helixConfig}
                    <ul>
                      {_.map(lead.helices, ({type, spacing, diameter}, i) => (
                        <li key={i}>
                          {_.capitalize(type)}&nbsp;
                          {smallUnitDisplay(diameter, settings.smallLengthUnit)}&nbsp;
                          <em>(spacing: {smallUnitDisplay(spacing, settings.smallLengthUnit)})</em>
                        </li>
                      ))}
                    </ul>
                    <small><em>*Note:  Helical spacing is conceptual.  Actual manufactured helix spacing may vary.</em></small>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </Page>
      <Page number="6">
        <div className="uk-grid uk-grid-small">
          <div className="uk-width-2-4">
            <h2>Allowable Capacity ({settings.forceUnit.abbreviation})</h2>
            <div style={ {maxHeight: '7.25in', overflow: 'hidden'} }>
              <CapacityCalcResults initialDisplay="capacity" project={project} maxDim={{width: 325, height: 700}} />
            </div>
          </div>
          <div className="uk-width-2-4">
            <h2>Torque ({torqueUnit.abbreviation})</h2>
            <div style={ {maxHeight: '7.25in', overflow: 'hidden'} }>
              <CapacityCalcResults project={project} maxDim={{width: 325, height: 700}} />
            </div>
          </div>
        </div>
      </Page>
      {hasCutSheet &&
        <div className="page">
          <div className="page-header">
            <img src={window.location.origin + LOGO} className="uk-float-right logo" />
          </div>
          <div className="page-footer">
            <p>MAGNUM GeoApp&reg; Software, Version {VERSION}, Copyright {CURRENT_YEAR}.&nbsp; <b>Page MP7</b></p>
          </div>
        </div>
      }
      <Page number="8">
        <h2>Limitations and Permissions for Use</h2>
        <p>Helical piles shall be installed to appropriate depth into suitable bearing stratum as determined by
          geotechnical engineer or local practice.  A minimum factor of safety of 2.0 is recommended for determining
          allowable capacity from correlations with final installation torque when there exists good geotechnical
          information for proper pile sizing.  A factor of safety of 2.5 or higher is recommended where geotechnical
          data is limited.  Net deflections of 0.5&quot; are typical at pile allowable capacity.  Higher factors of safety
          may be required for smaller deflections and for movement sensitive structures.  </p>
        <p>Results shown depend on proper helix geometry, helix spacing, and installation methods.  Therefore, the
          results apply only to helical piles manufactured by Magnum Piering, Inc. and installed by Magnum authorized
          installers.  Use of these results for other helical pile systems by other installers is hereby prohibited.  </p>
        <p>These calculations may be used for preliminary pile sizing and estimation purposes.  Final pile design should
          be by a registered design professional.  Helical pile performance is a function of installation, which Magnum
          cannot control, and ground conditions, which can vary with location and depth; as such, no warrantee is made,
          express or implied.  This software checks only individual bearing methods of capacity estimation.  The results
          depend on proper helix spacing to avoid reductions based on cylindrical shear modes.  Magnum® helix bearing
          elements are spaced to generally provide similar capacity for individual bearing and cylindrical shear modes.
          Nonetheless, it is recommended design professionals check cylindrical shear modes of bearing/pullout capacity
          development where they may represent a limiting scenario.  </p>
        <p>Magnum recommends bidders include provisions for additional pile length and/or obstructions in their bid
          documents.  Magnum recommends installers keep helical pile extensions inventoried on site to avoid delays
          should subsurface conditions vary and additional length be required.  Magnum recommends installation and pile
          load tests be performed where practicable to verify helical pile size, depth, and capacity.</p>
      </Page>
    </div>
  );
}
