import { std } from 'mathjs';
import { map, reverse } from 'lodash/fp';
import { MAX_LEVEL, BLOW_COUNT_INTERVAL_E } from 'lib/constants';
import SoilLabels from 'config/soil-labels.js';

const VALID_SOIL_VALUES = _.map(SoilLabels, 'value');
const SOIL_VALUES_FOR_LABELS = _.reduce(SoilLabels, (acc, soilLabel) => ({
  ...acc, [soilLabel.label.toLowerCase()]: soilLabel.value
}), {});
const normalizeSoilType = (value) => SOIL_VALUES_FOR_LABELS[_.toLower(value)] || _.toLower(value);

const DEFAULT_ROWS = MAX_LEVEL / BLOW_COUNT_INTERVAL_E + 1;

const allUniqueErrorsFor = _.flow([_.flatten, map('error'), _.compact, _.uniq])

const valuePresent = ([depthCell, ...row]) => _.some(row, ({value}) => value.length > 0);
const lastPresentRow = (data) => _.find(reverse(data), valuePresent) || _.last(data);
const getDepth = (row) => _.toNumber(_.get(row, [0, 'value']));
const roundedDepth = (row) => _.round(getDepth(row));
const getSoilType = (row) => _.get(row, [1, 'value']);

function aggregateSoil(groupedRows, calculate=_.noop, limitDepth) {
  let lastValue = 0;

  return _.times(DEFAULT_ROWS, (index) => {
    const depth = index * BLOW_COUNT_INTERVAL_E;
    if (depth >= limitDepth) return lastValue = 0;

    const valuesForRow = _.flatMap(groupedRows[depth], (row) => {
      return _.compact(row.slice(2).map((col) => _.toNumber(col.value)));
    });
    return lastValue = valuesForRow.length ? calculate(valuesForRow) : lastValue;
  });
}

function changedSoilType(previousRow, currRow) {
  const previousSoilType = previousRow && getSoilType(previousRow);
  const soilType = getSoilType(currRow);

  return previousSoilType && soilType && previousSoilType != soilType;
}

export function validateData({data}) {
  data = _.map(data, (row, i) => {
    if (i === 0) return row;

    const previousRow = data[i-1];

    delete row[0].error;
    const previousDepth = previousRow && getDepth(previousRow);
    const depth = getDepth(row);
    if (previousRow && depth < previousDepth) {
      row[0].error = 'Out of order depth';
    }

    delete row[1].error;
    if (i === 1 && !row[1].value) {
      row[1].error = 'Missing initial soil type';
    }
    if (changedSoilType(previousRow, row) && roundedDepth(row) == _.round(previousDepth)) {
      row[1].error = 'Multiple soil types within one foot increment';
    }
    row[1].value = normalizeSoilType(row[1].value);
    if (row[1].value && !_.includes(VALID_SOIL_VALUES, row[1].value)) {
      row[1].error = 'Soil type is not recognized';
    }

    return row;
  });

  return {data, errors: allUniqueErrorsFor(data)};
}

export default function parseSoil(data, {lengthUnit}) {
  data = data.map(([depthCell, ...row]) => [{value: lengthUnit.toBase(depthCell.value)}, ...row]);
  const rowsWithSoilTypeChanges = _.uniqBy(data.reduce((acc, row) => {
    const previousValue = getSoilType(_.last(acc));
    return getSoilType(row) && getSoilType(row) != previousValue ? [...acc, row] : acc;
  }, []), roundedDepth);

  const [surface, ...boundaries] = rowsWithSoilTypeChanges.map(roundedDepth);
  const groupedRows = _.groupBy(data, (row) => Math.round(getDepth(row)/BLOW_COUNT_INTERVAL_E)*BLOW_COUNT_INTERVAL_E);
  const limitDepth = (getDepth(lastPresentRow(data)) || MAX_LEVEL) + BLOW_COUNT_INTERVAL_E;

  return {
    blowCounts: aggregateSoil(groupedRows, _.mean, limitDepth),
    soilBoundaries: [...boundaries, MAX_LEVEL],
    soilTypes: rowsWithSoilTypeChanges.map((row, index) => ({id: index, name: getSoilType(row)})),
    blowCountsSD: aggregateSoil(groupedRows, std, limitDepth)
  }
}
