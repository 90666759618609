import { hashHistory } from 'react-router';
import UserForm from 'components/user/UserForm';
import { withOnline } from 'components/helpers/online-helper';

const goToTermsPage = () => hashHistory.push({pathname: '/terms'});

export default withOnline(function Registration({online}) {
  if (!online) {
    return (
      <div className="registration-page">
        <div className="padding">
          <h2>Sign up</h2>
          <p className="error">You must be online to sign up.</p>
        </div>
      </div>
    );
  }

  return (
    <div className="registration-page">
      <UserForm actionLabel="Register" onSuccess={goToTermsPage}/>
    </div>
  );
});
