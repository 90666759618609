import SoilLogEditCallout from './SoilLogEditCallout';
import SoilLogInfoCallout from './SoilLogInfoCallout';
import { pathify } from 'lib/svg-helpers';

const soilPath = ({x, y, width, height, angle}) => {
  const angleOffset = Math.tan((angle * Math.PI) / 180) * width / 2;
  const depthAngleAdjustment = 1 / Math.cos((angle * Math.PI) / 180);
  height = height * depthAngleAdjustment;
  y = y * depthAngleAdjustment;
  return pathify([
    {x: x, y: y - angleOffset},
    {x: x + width, y: y + angleOffset},
    {x: x + width, y: y + height + angleOffset},
    {x: x, y: y + height - angleOffset}
  ]) + 'z';
}

const DEFAULT_WIDTH = 10;

export default class SoilSelector extends React.Component {
  findClickLevel = (event) => {
    const {startLevel, endLevel} = this.props;
    const {height, top} = this.refs.soilIndicator.getBoundingClientRect();
    const pixelRatio = height / (endLevel - startLevel);
    const delta = (event.clientY || event.changedTouches[0].clientY) - top;
    return Math.max(1, Math.round(startLevel + (delta / pixelRatio)));
  }

  displayCallout = (event) => {
    event.preventDefault();
    const {
      setCallout, readonly, startLevel, endLevel,
      soilType:selectedSoil, updateSoilType:setSoil, splitSoilLayer
    } = this.props;
    if (!setCallout) return;

    const level = this.findClickLevel(event);

    if (readonly) {
      setCallout({Component: SoilLogInfoCallout, selectedSoil, level, startLevel, endLevel});
    } else {
      setCallout({
        Component: SoilLogEditCallout,
        level, selectedSoil, setSoil, splitSoilLayer
      });
    }
  }

  render() {
    const {startLevel, endLevel, factorY, soilType, width=DEFAULT_WIDTH, angle=0} = this.props;
    const height = (endLevel - startLevel) * factorY;
    return (
      <g className="soil-selector">
        <path
          ref="soilIndicator"
          d={soilPath({x: 0, y: startLevel * factorY, width, height, angle})}
          className={`soil-type ${soilType}`}
          style={{fill: `url(#pattern-${soilType})`}}
          onClick={this.displayCallout}
        >
          <title>{soilType}</title>
        </path>
      </g>
    );
  }
}
