import {soilTestUnit} from 'config/soil-test-units';
import * as DEFAULTS from 'reducers/projects/defaults';

function pickClean(obj, ...propNames) {
  return _.pickBy(_.pick(obj, propNames), (v) => !_.isUndefined(v));
}

function lengthUnit(state, action) {
  switch(action.type) {
    case 'RECEIVED_NEW_UNIT_TYPE':
      return action.lengthUnit;
    default:
      return state;
  }
}

function smallLengthUnit(state, action) {
  switch(action.type) {
    case 'RECEIVED_NEW_UNIT_TYPE':
      return action.smallLengthUnit;
    default:
      return state;
  }
}

function forceUnit(state, action) {
  switch(action.type) {
    case 'RECEIVED_NEW_UNIT_TYPE':
      return action.forceUnit;
    default:
      return state;
  }
}

function interval(state, action) {
  switch(action.type) {
    case 'RECEIVED_NEW_UNIT_TYPE':
      return action.interval;
    default:
      return state;
  }
}

function testUnit({testUnit, system}, action) {
  switch(action.type) {
    case 'RECEIVED_NEW_TEST_UNIT_TYPE':
      return soilTestUnit(action.testUnitType, system);
    case 'RECEIVED_NEW_UNIT_TYPE':
      return soilTestUnit(testUnit.value, action.system);
    default:
      return testUnit;
  }
}

function unitType(state, action) {
  switch(action.type) {
    case 'RECEIVED_NEW_UNIT_TYPE':
      return action.unitType;
    default:
      return state;
  }
}

function system(state, action) {
  switch(action.type) {
    case 'RECEIVED_NEW_UNIT_TYPE':
      return action.system;
    default:
      return state;
  }
}

function includeShaftFriction(state, action) {
  switch(action.type) {
    case 'INCLUDE_SHAFT_FRICTION':
      return action.boolean;
    default:
      return state;
  }
}

function reduceShaftFriction(state, action) {
  switch(action.type) {
    case 'REDUCE_SHAFT_FRICTION':
      return action.boolean;
    default:
      return state;
  }
}


export default function settings(state=DEFAULTS.SETTINGS, action) {
  switch(action.type) {
    case 'SET_PROJECT_SETTINGS':
      return {...state, ...action.settings};
    case 'SET_PILE_FILTER':
      return {...state,
        pile: {...state.pile, ...pickClean(action,
          'helixConfig', 'prefix', 'diameter', 'pitch', 'length', 'coupling', 'coating', 'wall', 'pileLength', 'includeDualCutter'
        )}
      };
    case 'SET_PILE':
      return {...state, pile: {
        ...state.pile,
        ...pickClean(action.lead,
          'helixConfig', 'prefix', 'diameter', 'pitch', 'coupling', 'wall'
        ),
        pileLength: action.pileLength
      }};
    case '__NOOP':
      return state;
    default:
      return {
        ...state,
        unitType: unitType(state.unitType, action),
        system: system(state.system, action),
        lengthUnit: lengthUnit(state.lengthUnit, action),
        smallLengthUnit: smallLengthUnit(state.smallLengthUnit, action),
        forceUnit: forceUnit(state.forceUnit, action),
        interval: interval(state.interval, action),
        testUnit: testUnit(state, action),
        includeShaftFriction: includeShaftFriction(state.includeShaftFriction, action),
        reduceShaftFriction: reduceShaftFriction(state.reduceShaftFriction, action)
      }
  }
}
