import qs from 'qs';
import {MARKER_COLORS} from './map';

export default function StaticMap(props) {
  const {height=400, width=400, zoom=10, latitude, longitude, maptype="roadmap"} = props;
  const params = {
    center: `${latitude},${longitude}`,
    markers: `color:0x${MARKER_COLORS['OWN']}|${latitude},${longitude}`,
    zoom,
    size: `${width}x${height}`,
    maptype,
    key: process.env.GOOGLE_API_KEY
  };

  return <img src={`https://maps.googleapis.com/maps/api/staticmap?${qs.stringify(params)}`} />
}
