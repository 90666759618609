import Repository from 'repository';
import store from 'store';

export default {
  setSoilTypes(soils) {
    store.dispatch({type: 'SET_SOIL_TYPES', soils});
    Repository.persistProject();
  },

  setSoilBoundaries(boundaries) {
    store.dispatch({type: 'SET_BOUNDARIES', boundaries});
    Repository.persistProject();
  },

  setBulkSoil(bulkSoil) {
    store.dispatch({type: 'SET_BULK_SOIL', bulkSoil});
    Repository.persistProject();
  },

  resetSoil() {
    store.dispatch({type: 'SOIL_RESET'});
    Repository.persistProject();
  },

  setBlowCountsSD(blowCountsSD) {
    store.dispatch({type: 'SET_BLOW_COUNT_SD', blowCountsSD});
    Repository.persistProject();
  }
};
