import { Capacitor } from '@capacitor/core';
import { Device } from '@capacitor/device';
import { render } from 'react-dom';
import { hashHistory } from 'react-router';
import uuid from 'lodash-uuid';
_.mixin(uuid, { chain: true });

import Rollbar from 'rollbar';
import rollbarConfig from 'config/rollbar';

import UserActions from 'actions/UserActions';
import PileSelectionActions from 'actions/PileSelectionActions';
import store, { loggedIn } from 'store';
import Repository from 'repository';

import App from 'app';
import 'styles/main.scss';


window.Rollbar = new Rollbar(rollbarConfig);

function getDeviceInfo() {
  return Device.getInfo().then((info) => {
    window.device = info;
    document.getElementById('root').classList.add(info.platform);
  });
}

function initRepository() {
  return Repository.loadLocalStorage().then(() => {
    Repository.persist();
    if (loggedIn()) {
      store.subscribe(_.throttle(Repository.syncOfflineProjects, 30000, {leading: true}));
      UserActions.loadUserInfo();
      PileSelectionActions.getProductTypes();
    }
    store.subscribe(() => {
      const {user: {userInfo={}}} = store.getState();
      userInfo.id = userInfo.id || userInfo.email;
      window.Rollbar.configure({payload: {
        person: _.pick(userInfo, 'id', 'email', 'name', 'global_editor', 'is_admin')
      }});
    });
  });
}

Promise.all([getDeviceInfo(), initRepository()]).then(() => {
  render(<App />, document.getElementById('root'));
});




hashHistory.listen((location) => {
  if (window.gtag) {
    window.gtag('config', process.env.GOOGLE_ANALYTICS_ID, {'page_path': location.pathname + location.search});
    window.gtag('event', 'pageview');
  }
});