import { capacities } from 'lib/capacity-calculations';

export default function RequiredCapacity({project}) {
  const {tension, compression} = project.data.loads;

  const maxCapacity = _.maxBy(capacities(project), 'x').x;
  const maxRequirement = _.max([tension, compression]);
  const requiredCapacityAchieved = maxCapacity >= maxRequirement;

  return (
    <div className="required-capacity">
      {requiredCapacityAchieved ? (
        <div className="success">Required Capacity Achieved!</div>
      ) : (
        <div className="incomplete">Required Capacity Not Achieved</div>
      )}
    </div>
  );
}
