import PropTypes from 'prop-types';
import maxGraphDepth from 'lib/graph-depth';
import { showPopup } from 'lib/ionic-utils';
import SoilActions from 'actions/SoilActions';
import BlowCountActions from 'actions/BlowCountActions';

import { bulkSoilConfirm } from 'components/helpers/confirm';
import SoilLogHelp from 'components/help-dialogs/SoilLogHelp';
import SoilLogGraph from './SoilLogGraph';
import BulkImport from './BulkImport';

export default class SoilLog extends React.Component {
  state = {
    callout: null,
    soilData: ''
  }

  static contextTypes = {
    ionUpdatePopup: PropTypes.func,
    ionShowModal: PropTypes.func
  }

  componentDidMount() {
    const notReportPage = _.isUndefined(this.props.maxDim);
    const userDismissed = _.get(this.props, 'user.settings') === undefined || _.get(this.props, 'user.settings.soil_help', false);
    if (notReportPage && !userDismissed) this.displayHelp();
  }

  displayHelp = () => {
    this.context.ionUpdatePopup({
      popupType: 'show',
      title: 'Help',
      subTitle: 'Tips to create a representative soil profile',
      template: <SoilLogHelp/>,
      buttons: [{
        text: 'Close',
        type: 'button-positive'
      }]
    });
  }

  setCallout = ({Component, ...calloutProps}={}) => {
    this.setState({callout: Component && <Component { ...calloutProps } />});
  }

  bulkImport = () => {
    return showPopup(this.context,
      <BulkImport save={this.setSoilData} project={this.props.project} />,
      "Bulk Import", "Cancel", {customClasses: "auto-width"}
    );
  }

  setSoilData = ({blowCounts, blowCountsSD, soilBoundaries, soilTypes, rawData}) => {
    this.context.ionUpdatePopup({});
    BlowCountActions.setBlowCounts(blowCounts);
    SoilActions.setBlowCountsSD(blowCountsSD);
    if (soilTypes.length === soilBoundaries.length) {
      SoilActions.setSoilTypes(soilTypes);
      SoilActions.setSoilBoundaries(soilBoundaries);
    } else {
      console.warn("something went wrong with the bulk soil parse", blowCounts, soilBoundaries, soilTypes, rawData)
    }
    SoilActions.setBulkSoil(rawData);
  }

  render() {
    const {readonly, project, maxDim} = this.props;
    return (
      <div className="soil-log" onClick={this.setCallout}>
        {!readonly && (
          <div style={{margin: '6px 0px 0px 20px'}}>
            <button className='button-reset' onClick={() => bulkSoilConfirm(project.data.bulkSoil, SoilActions.resetSoil)} style={{zIndex: 10}}>
              RESET
            </button>
            <button className='button button-small float-right bulk-import' onClick={this.bulkImport} style={{zIndex: 10}}>
              BULK IMPORT
            </button>
          </div>
        )}
        <div style={{position: 'relative'}}>
          <SoilLogGraph {...project.data} {...project.settings}
            maxDim={maxDim}
            maxDepth={maxGraphDepth(project)}
            readonly={readonly}
            setCallout={this.setCallout} />
          {this.state.callout}
        </div>
      </div>
    );
  }
}
