export default {
  "degrees": {
    "value": "degrees",
    "label": "degrees",
    "abbreviation": "°",
    "toBase": function(value) {
      return value/1;
    },
    "toDisplay": function(value) {
      return value/1;
    }
  }
}
