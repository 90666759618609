import { hashHistory } from 'react-router';
import UserActions from 'actions/UserActions';
import UserStore from 'stores/UserStore';
import PasswordReset from 'components/user/PasswordReset';
import ChangePassword from 'components/user/ChangePassword';

export default class PasswordManager extends React.Component {
  state = {
    email: '',
    submittedCode: '',
    newPassword: '',
    errors: '',
    token: UserStore.getResetToken()
  }

  componentDidMount() {
    this.unsubscribeUserStore = UserStore.subscribe(() => {
      if (UserStore.loggedIn()) {
        return hashHistory.push({pathname: '/projects'});
      }
      this.setState({token: UserStore.getResetToken(), errors: UserStore.getErrors()});
    });
    if (_.get(this.props, 'location.query.t')) {
      const {t, email} = this.props.location.query;
      this.setState({fullToken: t, token: t, email});
    }
  }

  componentWillUnmount() {
    this.unsubscribeUserStore();
  }

  set = (field) => (e) => this.setState({[field]: e.target.value, errors: ''});

  sendPasswordResetRequest = () => {
    const email = _.trim(this.state.email);
    if (email.length > 0) {
      this.setState({errors: ""});
      UserActions.passwordReset(email);
    } else {
      this.setState({errors: "Please enter an email address"});
    }
  }

  valid = () => {
    const {newPassword, submittedCode, fullToken} = this.state;
    const errors = [];
    if (!fullToken && submittedCode.length !== 6) errors.push("Security code must be 6 characters.");
    if (newPassword.length < 8) errors.push("Passwords must be 8 characters or longer.");
    this.setState({errors: errors.join(' ')});
    return !errors.length;
  }

  changePassword = () => {
    const {email, newPassword, token, fullToken, submittedCode} = this.state;
    const authToken = fullToken || token + submittedCode;
    if (this.valid()) {
      UserActions.changePassword(newPassword, authToken, email);
    }
  }

  resetToken = () => this.setState({token: '', errors: ''})

  render() {
    const {token, fullToken, errors, submittedCode, newPassword, email} = this.state;
    return (
      <div>
        {errors && <div className="padding"><p className="error">{errors}</p></div>}

        {token ? (
          <ChangePassword
            submittedCode={fullToken ? 'prefilled' : submittedCode}
            onCodeChange={this.set('submittedCode')}
            newPassword={newPassword}
            onPasswordChange={this.set('newPassword')}
            changePassword={this.changePassword}
            resetToken={this.resetToken} />
        ) : (
          <PasswordReset
            value={email}
            inputChange={this.set('email')}
            sendPasswordRequest={this.sendPasswordResetRequest} />
        )}
      </div>
    );
  }
}
