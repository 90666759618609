import { Link } from 'react-router';
import { IonView, IonContent, IonTabs, IonTab } from 'reactionic';

export default function UnauthenticatedLayout({children}) {
  return (
    <IonView>
      <IonTabs tabsTop={true} customClasses="tabs-striped tabs-top tabs-background-dark tabs-color-light tabs-no-headerbar">
        <IonTab to="/login" label="Log in" />
        <IonTab to="/registration" label="Sign up" />
      </IonTabs>
      <IonContent customClasses="has-tabs-top">
        {children}
      </IonContent>
    </IonView>
  );
}
