import PropTypes from 'prop-types';
import { IonIcon, IonSideMenu, IonList, IonItem } from 'reactionic';
import HelpMessage from 'components/helpers/HelpMessage';
import preventDefault from 'components/helpers/event';
import { phoneMagnum } from 'config/help-text';
import { showPopup } from 'lib/ionic-utils';

const PHONE_MESSAGE_TITLE = 'Phone Magnum';

export default class MainNav extends React.Component {
  static contextTypes = {
    ionSnapper: PropTypes.object,
    ionUpdatePopup: PropTypes.func
  }
  close = () => this.context.ionSnapper.close()

  phoneMagnum = () => {
    showPopup(this.context, <HelpMessage content={phoneMagnum}/>, PHONE_MESSAGE_TITLE);
    this.close();
  }

  render() {
    const {user={}, loggedIn} = this.props;

    return (
      <IonSideMenu side="right" customClasses="side-menu">
        <IonList>
          <IonItem iconLeft onClick={this.close}>
            <IonIcon icon="ios-arrow-left"/>
            Close Menu
          </IonItem>
          {loggedIn ? (
            <div>
              <IonItem divider>
                Signed in as:<br/> {user.email}
                {user.is_admin && (
                  <div style={{fontSize: '0.8em', fontWeight: 'bold', opacity: 0.7}}>ADMIN</div>
                )}
                {user.global_editor && (
                  <div style={{fontSize: '0.8em', fontWeight: 'bold', opacity: 0.7}}>GLOBAL EDITOR</div>
                )}
              </IonItem>
              <IonItem iconLeft link="/account" onClick={this.close}>
                <IonIcon icon="person"/>
                Edit Account
              </IonItem>
              <IonItem iconLeft link="/projects" onClick={this.close}>
                <IonIcon icon="briefcase"/>
                My Projects
              </IonItem>
              <IonItem iconLeft link="/logout" onClick={this.close}>
                <IonIcon icon="log-out"/>
                Log Out
              </IonItem>
            </div>
          ) : (
            <div>
              <IonItem iconLeft link="/login" onClick={this.close}>
                <IonIcon icon="log-in"/>
                Log In
              </IonItem>
            </div>
          )}
          <IonItem divider>
            Magnum Piering GeoApp&reg;
          </IonItem>
          <IonItem iconLeft href="mailto:help@magnumpiering.com">
            <IonIcon icon="email"/>
            Email Support
          </IonItem>
          <IonItem iconLeft href="http://www.magnumpiering.com" target="_blank">
            <IonIcon icon="help-buoy"/>
            Launch Website
          </IonItem>
          <IonItem iconLeft href="#" onClick={preventDefault(this.phoneMagnum)}>
            <IonIcon icon="ios-telephone"/>
            Phone Magnum
          </IonItem>
        </IonList>
      </IonSideMenu>
    );
  }
}
