export function pathify(coords) {
  return 'M ' + coords.map( coord => {
    return [coord.x, coord.y].join(' ');
  }).join(" L ");
}

export function groupTranslate({offsetX, offsetY}={}) {
  return (x, y) => {
    x = x || offsetX || 0;
    y = y || offsetY || 0;
    return `translate(${x},${y})`;
  }
}

export default {pathify, groupTranslate}
