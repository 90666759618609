export default function Lead({lead, setLead}) {
  return (
    <li className="item item-button-right">
      {lead.helixConfig}
      <button className="button button-positive" onClick={() => setLead(lead)}>
        <i className="icon ion-plus" />
      </button>
    </li>
  );
}
