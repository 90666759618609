import UserStore from 'stores/UserStore';
import PartList from 'lib/part-list';
import PileOptions from 'components/project/PileOptions';
import PileGraph from 'components/project/pile-visualizer/PileGraph';

export default function Pile({project}) {
  const {global_editor=false} = UserStore.getInfo();
  const {settings, data} = project;
  const readonly = !project.owned && !global_editor;

  return (
    <div className="row">
      <div className="pile-graph col-25">
        <PileGraph {...data} {...settings}
          settings={settings}
          shafts={PartList.shafts(data.pileSegments)}
          helices={PartList.helices(data.pileSegments)}/>
      </div>
      <div className="col-75">
        <PileOptions data={data} settings={settings} readonly={readonly} pileOptions={settings.pile}/>
      </div>
    </div>
  );
}
