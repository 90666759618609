const BLOWS70_TO_BLOWS60 = 0.857142857;
const BLOWS70_TO_BLOWS80 = 1.142857143;
const BLOWS70_TO_BLOWS90 = 1.285714286;
const BLOWS70_TO_PSI = 0.785714286;
const TSF_TO_PSI = 13.89;
const PSI_TO_KPA = 6.89476;
const TSF_TO_KPA = 95.76;

const CPT_METRIC = {
  "unit": "kPa",
  "maxScale": 7000,
  "precision": -1,
  "toBase": (value, soil) => value*BLOWS70_TO_PSI*soil.coneCoef/PSI_TO_KPA,
  "toDisplay": (value, soil) => value/BLOWS70_TO_PSI/soil.coneCoef*PSI_TO_KPA
};

const UNITS = {
  "SPT 70": {
    "value": "SPT 70",
    "label": "SPT 70",
    "abbreviation": "N<sub>70</sub>",
    "description": "SPT blow count at 70% energy",
    "english": {
      "unit": "blows/ft",
      "maxScale": 100,
      "precision": 0,
      "toBase": (value) => value/1,
      "toDisplay": (value) => value/1
    },
    "metric": {
      "unit": "blows/30cm",
      "maxScale": 100,
      "precision": 0,
      "toBase": (value) => value/1,
      "toDisplay": (value) => value/1
    }
  },
  "SPT 60": {
    "value": "SPT 60",
    "label": "SPT 60",
    "abbreviation": "N<sub>60</sub>",
    "description": "SPT blow count at 60% energy",
    "english": {
      "unit": "blows/ft",
      "maxScale": 100,
      "precision": 0,
      "toBase": (value) => value*BLOWS70_TO_BLOWS60,
      "toDisplay": (value) => value/BLOWS70_TO_BLOWS60
    },
    "metric": {
      "unit": "blows/30cm",
      "maxScale": 100,
      "precision": 0,
      "toBase": (value) => value*BLOWS70_TO_BLOWS60,
      "toDisplay": (value) => value/BLOWS70_TO_BLOWS60
    }
  },
  "SPT 80": {
    "value": "SPT 80",
    "label": "SPT 80",
    "abbreviation": "N<sub>80</sub>",
    "description": "SPT blow count at 80% energy",
    "english": {
      "unit": "blows/ft",
      "maxScale": 100,
      "precision": 0,
      "toBase": (value) => value*BLOWS70_TO_BLOWS80,
      "toDisplay": (value) => value/BLOWS70_TO_BLOWS80
    },
    "metric": {
      "unit": "blows/30cm",
      "maxScale": 100,
      "precision": 0,
      "toBase": (value) => value*BLOWS70_TO_BLOWS80,
      "toDisplay": (value) => value/BLOWS70_TO_BLOWS80
    }
  },
  "SPT 90": {
    "value": "SPT 90",
    "label": "SPT 90",
    "abbreviation": "N<sub>90</sub>",
    "description": "SPT blow count at 90% energy",
    "english": {
      "unit": "blows/ft",
      "maxScale": 100,
      "precision": 0,
      "toBase": (value) => value*BLOWS70_TO_BLOWS90,
      "toDisplay": (value) => value/BLOWS70_TO_BLOWS90
    },
    "metric": {
      "unit": "blows/30cm",
      "maxScale": 100,
      "precision": 0,
      "toBase": (value) => value*BLOWS70_TO_BLOWS90,
      "toDisplay": (value) => value/BLOWS70_TO_BLOWS90
    }
  },
  "CPT": {
    "value": "CPT",
    "label": "CPT qc",
    "abbreviation": "qc",
    "description": "CPT cone tip resistance",
    "english": {
      "unit": "psi",
      "maxScale": 2000,
      "precision": -1,
      "toBase": (value, soil) => value*BLOWS70_TO_PSI*soil.coneCoef,
      "toDisplay": (value, soil) => value/BLOWS70_TO_PSI/soil.coneCoef
    },
    "metric": CPT_METRIC
  },
  "CPT tsf": {
    "value": "CPT tsf",
    "label": "CPT qc",
    "abbreviation": "qc",
    "description": "CPT cone tip resistance",
    "english": {
      "unit": "tsf",
      "maxScale": 200,
      "precision": 0,
      "toBase": (value, soil) => value*BLOWS70_TO_PSI*soil.coneCoef*TSF_TO_PSI,
      "toDisplay": (value, soil) => value/BLOWS70_TO_PSI/soil.coneCoef/TSF_TO_PSI
    },
    "metric": {...CPT_METRIC, copyOf: 'CPT'}
  },
  "STR": {
    "value": "STR",
    "label": "STR Su",
    "abbreviation": "Su",
    "description": "Average undrained strength",
    "english": {
      "unit": "tsf",
      "maxScale": 10,
      "precision": 2,
      "toBase": (value, soil) => value/0.0072/soil.soilCoef,
      "toDisplay": (value, soil) => value*0.0072*soil.soilCoef
    },
    "metric": {
      "unit": "kPa",
      "maxScale": 1000,
      "precision": -1,
      "toBase": (value, soil) => value/0.0072/soil.soilCoef/TSF_TO_KPA,
      "toDisplay": (value, soil) => value*0.0072*soil.soilCoef*TSF_TO_KPA
    }
  },
  "UC": {
    "value": "UC",
    "label": "UC qu",
    "abbreviation": "qu",
    "description": "Unconfined compressive strength",
    "english": {
      "unit": "tsf",
      "maxScale": 20,
      "precision": 2,
      "toBase": (value, soil) => value/0.0144/soil.soilCoef,
      "toDisplay": (value, soil) => value*0.0144*soil.soilCoef
    },
    "metric": {
      "unit": "kPa",
      "maxScale": 2000,
      "precision": -1,
      "toBase": (value, soil) => value/0.0144/soil.soilCoef/TSF_TO_KPA,
      "toDisplay": (value, soil) => value*0.0144*soil.soilCoef*TSF_TO_KPA
    }
  }
};

const soilTestUnit = (soilUnit, system) => {
  const unit = UNITS[soilUnit];
  return {...unit, ...unit[system]};
};

export default UNITS;
export {soilTestUnit};
