import {useState} from 'react';

import { Link, hashHistory } from 'react-router';

import { IonContent, IonNavBar, IonButton } from 'reactionic';
import UserActions from 'actions/UserActions';
import AuthenticationActions from 'actions/AuthenticationActions';
import UserStore from 'stores/UserStore';
import {withOnline} from 'components/helpers/online-helper';
import FormRow from 'components/helpers/form-row';

export default withOnline(function UserDestroy({online, location}) {
  const [email, setEmail] = useState(_.get(location, 'query.email') || '');
  const token = _.get(location, 'query.t');
  const errors = UserStore.getErrors();
  const destroySuccess = UserStore.getDestroySuccess();
  const destroyRequestSuccess = UserStore.getDestroyRequestSuccess();

  const handleDelete = () => {
    UserActions.delete().then(() => {
      AuthenticationActions.logout();
      hashHistory.push({pathname: '/'});
    }).catch((error) => {
      console.warn(error);
    });
  };

  const isAuthorized = email && token;

  return (
    <div>
      <IonNavBar customClasses="bar-dark" title="Delete Account"/>
      <IonContent customClasses="has-header">
        <div className="padding">
          {errors && <p className="error">{errors}</p>}
          {destroySuccess && <p className="success">Your account has been deleted.</p>}
          {destroyRequestSuccess && <p className="success">An email has been sent to confirm account deletion.</p>}
          {(UserStore.loggedIn() || isAuthorized) ? <>
            If you delete your account, all of your projects will also permanently be deleted after backups are purged in 60 days.
            <div>
              <Link className="button button-warning"
                onClick={UserStore.loggedIn() ? handleDelete : () => UserActions.deleteUnauthenticated({email, token})}>
                Permanently Delete Account and Projects
              </Link>
            </div>
          </> : <>
            Enter your email below to send an email to your account to delete it.
            <form className="list" onSubmit={() => UserActions.requestDestroy(email)}>
              <FormRow label="Email">
                <input type="text" value={email} onChange={(e) => setEmail(e.target.value)}/>
              </FormRow>
              <button className="button button-block button-positive" type="submit">
                Delete Account
              </button>
            </form>
          </>}
        </div>
      </IonContent>
    </div>
  );
});
