import classnames from 'classnames';
import { IonIcon } from 'reactionic';
import ProjectSettingsActions from 'actions/ProjectSettingsActions';

export default function NumberInput({label, property, value, unit, max, min, readonly, help, error, className}) {
  const displayValue = _.round(unit.toDisplay(value), 2);
  const setValue = ({target}) => {
    const baseValue = unit.toBase(target.value);
    ProjectSettingsActions.set({[property]: baseValue});
  };
  return (
    <label className={classnames('item item-input item-input-right', {readonly}, className)}>
      <span className="input-label" onClick={help}>
        {label}{help && <IonIcon icon="help-circled"/>}:
      </span>
      <span className="error-alert" onClick={error}>
        {error && <IonIcon icon="alert-circled" />}
      </span>
      <input type="number" value={displayValue} disabled={readonly} max={max} min={min} step="1" onChange={setValue}/>
      <span className="unit">{unit.label}</span>
    </label>
  );
}
