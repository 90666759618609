import { IonIcon } from 'reactionic';
import SoilLabels from 'config/soil-labels';
import SoilLogCallout from './SoilLogCallout';

const selectedIcon = <IonIcon icon="checkmark-circled extra-large"/>;
const swatchFor = (soilType) => <div className={`swatch ${soilType}`}/>;

export default function SoilLogEditCallout({y, level, splitSoilLayer, selectedSoil, setSoil}) {
  return (
    <SoilLogCallout y={y}>
      <ul>
        <li onClick={() => splitSoilLayer(level)}>
          <IonIcon icon="plus-circled"/> Add Boundary
        </li>
        <hr/>
        {SoilLabels.map(({label, value}) => {
          const isSelected = value === selectedSoil;
          return (
            <li key={value} onClick={() => setSoil(value)}>
              {isSelected ? selectedIcon : swatchFor(value)} {label}
            </li>
          );
        })}
      </ul>
    </SoilLogCallout>
  );
}
