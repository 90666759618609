import { Link, hashHistory } from 'react-router';
import AuthenticationActions from 'actions/AuthenticationActions';
import store from 'store';
import UserStore from 'stores/UserStore';

export default class Login extends React.Component {
  state = {
    email: '',
    password: '',
    error: false
  }

  componentDidMount() {
    this.unsubscribe = UserStore.subscribe(this.redirectOnSuccess);
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  redirectOnSuccess = () => {
    if (UserStore.loggedIn()) {
      const {location} = this.props;

      if (location.state && location.state.nextPathname) {
        hashHistory.replace(location.state.nextPathname);
      } else {
        hashHistory.push('/projects');
      }
    } else if (UserStore.getErrors() != '') {
      return this.setState({error: true});
    }
  }

  login = (event) => {
    event.preventDefault();
    const {email, password} = this.state;
    AuthenticationActions.login(email, password);
  }

  set = (field) => (e) => this.setState({[field]: e.target.value, error: false})

  render() {
    const {email, password, error} = this.state;
    const {online} = store.getState();
    if (!online) {
      return (
        <div className="login-page">
          <div className="padding">
            <h2>Welcome</h2>
            <p className="error">You must be online to login.</p>
          </div>
        </div>
      );
    }

    return (
      <div className="login-page">
        <div className="padding">
          <h2>Welcome</h2>
          {error && (
            <p className="error">The email or password you entered is incorrect</p>
          )}
        </div>
        <form className="list" onSubmit={this.login}>
          <label className="item item-input item-stacked-label">
            <span className="input-label">Email</span>
            <input type="email" value={email} onChange={this.set('email')} placeholder="yourname@example.com" autoComplete="username" required />
          </label>

          <label className="item item-input item-stacked-label">
            <span className="input-label">Password</span>
            <input type="password" value={password} onChange={this.set('password')} placeholder="****" autoComplete="current-password" required />
          </label>

          <div className="padding">
            <button className="button button-block button-positive" type="submit">Login</button>
          </div>

          <div className="padding">
            <Link to="/passwordmanager" className="button button-block button-dark">Reset Password</Link>
          </div>
        </form>
      </div>
    );
  }
}
