import { IonIcon } from 'reactionic';
import ProjectSettingsActions from 'actions/ProjectSettingsActions';

export default function PileCount({pileCount, readonly}) {
  const changePileCount = (event) => ProjectSettingsActions.setPileCount(event.target.value);

  return (
    <label className="item item-input">
      <span className="input-label">
        Pile Count:
      </span>
      {readonly ? (
        <span className="readonly-value">{pileCount}</span>
      ) : (
        <input type="number" min="1" step="1" value={pileCount} onChange={changePileCount}/>
      )}
    </label>
  );
}
