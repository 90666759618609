import PropTypes from 'prop-types';
import { Link, hashHistory } from 'react-router';
import { IonNavView, IonView, IonContent, IonHeaderBar, IonButton, IonPopoverButton, IonIcon,
         IonSpinner, IonTabs, IonTab, IonFooterBar } from 'reactionic';
import ProjectStore from 'stores/ProjectStore';
import UserStore from 'stores/UserStore';
import ProjectActions from 'actions/ProjectActions';
import ProjectMenu from 'components/project/ProjectMenu';
import RequiredCapacity from 'components/project/RequiredCapacity';
import { appUpdateRequired } from 'helpers/project-schema';

const PILE_PATH = /pile/i;

const loadingScreen = (
  <div className="loading-container visible active">
    <div className="loading">
      <IonSpinner />
    </div>
  </div>
);

function MoreButton({project, ionShowPopover}) {
  return (
    <IonPopoverButton
      icon="ion-more"
      onClick={() => ionShowPopover(<ProjectMenu project={project}/>)}
      color="calm"
      type="clear"/>
  );
}

export default class ProjectLayout extends React.Component {
  constructor(props) {
    super(props)
    const {id} = props.params;
    const project = ProjectStore.getProjectById(id);
    const status = ProjectStore.getProjectStatus();
    const user = UserStore.getInfo();

    if (!project) {
      ProjectActions.getProject(id);
    }

    if (!user) {
      UserStore.getInfo();
    }
    this.state = { project, user, status };
  }

  static contextTypes = {
    ionShowPopover: PropTypes.func
  }

  componentDidMount() {
    _.defer(ProjectActions.setCurrentProject, this.props.params.id);
    this.unsubscribeProjectStore = ProjectStore.subscribe(this._onChange);
    this.unsubscribeUserStore = UserStore.subscribe(this._onChange);
  }

  componentWillUnmount() {
    this.unsubscribeProjectStore();
    this.unsubscribeUserStore();
  }

  _onChange = () => {
    const {id} = this.props.params;
    const project = ProjectStore.getProjectById(id);
    const status = ProjectStore.getProjectStatus();
    if (project) {
      this.setState({project, status, user: UserStore.getInfo()});
    }
  }

  componentDidUpdate(prevProps, _prevState, _snapshot) {
    if (this.props === prevProps) return;

    const {id} = this.props.params || {};
    const project = ProjectStore.getProjectById(id);
    const status = ProjectStore.getProjectStatus();

    if (id !== prevProps.params.id && id != status.projectID) {
      ProjectActions.setCurrentProject(id);
      if (!project.createdOfflineAt) ProjectActions.getProject(id);
    }

    if (!project && this.state.project) {
      hashHistory.push('/projects/');
    } else {
      this.setState({project, status});
    }
  }

  render() {
    const {location, footer} = this.props;
    const {project={}, status, user} = this.state;
    const {id, owned, name, data, settings} = project;
    const readonly = !project.owned && !user.global_editor;

    const onPilePath = PILE_PATH.test(location.pathname);

    const projectLink = (
      <Link to={`/projects/${id}/settings`} className="title-link">
        {name}
      </Link>
    );
    const backButton = (
      <IonButton icon="ion-chevron-left"
        iconPosition="left"
        link={owned ? "/projects" : "/global-projects"}
        color="calm"
        type="clear">
        Projects
      </IonButton>
    );


    if (project && name && user) {
      if (appUpdateRequired(project)) {
        return (
          <IonView key={id}>
            <IonHeaderBar customClasses="bar-dark"
              title={name}
              leftButton={backButton} />
            <IonContent customClasses="has-header">
              <div className="padding">
                <p className="error">
                  This project was created with a newer version of GeoApp. Please update to view.
                </p>
              </div>
            </IonContent>
          </IonView>
        );
      }

      return (
        <IonView key={id}>
          <IonHeaderBar customClasses="bar-dark project-title"
            title={ projectLink }
            leftButton={ backButton }
            rightButton={<MoreButton project={project} ionShowPopover={this.context.ionShowPopover}/>}/>
          <IonTabs tabsTop={true} customClasses="tabs-striped tabs-top tabs-color-positive">
            <IonTab to={`/projects/${id}/loads`} label="Loads" />
            <IonTab to={`/projects/${id}/soil`} label="Soil" />
            <IonTab to={`/projects/${id}/pile`} label="Pile" />
            <IonTab to={`/projects/${id}/results`} label="Results" />
          </IonTabs>
          {onPilePath && <RequiredCapacity project={project}/>}
          <IonContent customClasses="has-tabs-top">
            { React.cloneElement(this.props.main, { project, user, readonly }) }
          </IonContent>
          {footer && (
            <IonFooterBar>{ React.cloneElement(footer, {project}) }</IonFooterBar>
          )}
        </IonView>
      );
    } else {
      return (
        <IonView key={id}>
          <IonHeaderBar customClasses="bar-dark"
            title={status.error ? "error" : "loading"}
            leftButton={backButton} />
          <IonContent customClasses="has-header">
            {status.error ? (
              <div className="padding"><p className="error">{status.errorMsg}</p></div>
            ) : loadingScreen}
          </IonContent>
        </IonView>
      );
    }
  }
}
