import { Link, hashHistory } from 'react-router';
import { IonContent, IonNavBar, IonItemCheckBox } from 'reactionic';
import RegistrationActions from 'actions/RegistrationActions';
import UserStore from 'stores/UserStore';
import { terms } from 'config/terms-conditions';

export default class Terms extends React.Component {
  state = {
    initialAccepted: UserStore.getRegStatus()?.accepted,
    regStatus: UserStore.getRegStatus(),
    newsletter: true
  }

  componentDidMount() {
    this.unsubscribeUserStore = UserStore.subscribe(this._onChange);
  }

  componentWillUnmount() {
    this.unsubscribeUserStore();
  }

  _onChange = () => {
    const regStatus = UserStore.getRegStatus();
    this.setState({regStatus});

    if (!this.state.initialAccepted && regStatus.accepted && UserStore.loggedIn()) {
      hashHistory.push('/projects');
    }
  }

  toggle = () => this.setState(({newsletter}) => ({newsletter: !newsletter}))

  handleSubmit = (event) => {
    event.preventDefault();
    const {regStatus: {userId}, newsletter} = this.state;
    RegistrationActions.acceptTerms(userId, newsletter);
  }

  render() {
    const {newsletter, regStatus, initialAccepted} = this.state;
    const inRegistrationFlow = regStatus.userId && !initialAccepted;
    return (
      <div>
        <IonNavBar customClasses="bar-dark" title="Terms and Conditions"/>
        <IonContent customClasses="has-header">
          <form className="" onSubmit={this.handleSubmit}>
            <div className="padding">
              <p>{terms}</p>
              <p>See <Link to="privacy" target={inRegistrationFlow ? "_blank" : null}>Privacy Policy</Link></p>
            </div>
            {inRegistrationFlow && <>
              <IonItemCheckBox
                label="Receive Quarterly Newsletter"
                checked={newsletter}
                handleChange={this.toggle} />
              <div className="padding">
                <button className="button button-block button-positive" type="submit">
                  Agree With Terms
                </button>
              </div>
            </>}
          </form>
        </IonContent>
      </div>
    );
  }
}
