import { Link } from 'react-router';
import { IonView, IonContent, IonHeaderBar, IonButton } from 'reactionic';

export default function NewProject({children}) {
  const backButton = (
    <IonButton icon="ion-chevron-left"
      iconPosition="left"
      link="/projects"
      color="light"
      type="clear"
    >
      Projects
    </IonButton>
  );
  return (
    <IonView>
      <IonHeaderBar customClasses="bar-dark"
        title="New Project"
        leftButton={backButton} />
      <IonContent customClasses="">
        {children}
      </IonContent>
    </IonView>
  );
}
