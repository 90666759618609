import SoilLabels from 'config/soil-labels.js';

const SoilTypeDropdown = ({onChange, value}) => {
  return (
    <select name="soil-type" onChange={e => onChange(e.target.value)} value={value}>
      <option></option>
      {SoilLabels.map((soil, i) => <option key={i} value={soil.value}>{soil.label}</option>)}
    </select>
  )
}

export default SoilTypeDropdown;
