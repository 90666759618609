function sine_curve(width, height) {
  return [
    'c', 0, height/2, width, height/2, width, height
  ].join(' ')
}

export default class HelixGraphic extends React.Component {
  dual = () => this.props.type === 'dual'
  width = () => this.props.diameter*this.props.widthFactor
  height = () => this.width() * (this.props.aspectFactor + 0.15 * this.props.pitch/3)
  side_width = () => (this.width() - this.props.shaftWidth) / 2
  half_circle = () => this.height() / 2

  top_half = (bottom) => [
    "M", -this.props.shaftWidth/2, (bottom ? this.props.thickness : 0),
    "l", -this.side_width(), 0,
    sine_curve(this.width(), this.half_circle()),
  ].join(" ")

  curve = (bottom) => [
    this.top_half(bottom),
    sine_curve(-this.width(), this.half_circle()),
    'l', this.side_width(), 0,
    sine_curve(this.props.shaftWidth, -this.half_circle()),
  ].join(" ")

  double_curve = (bottom) => [
    this.top_half(bottom),
    'l', -this.width()*(this.props.toothFactor/2), 0,
    sine_curve(-this.width()*(1 - this.props.toothFactor), this.half_circle()),
    'l', (this.width()*(1 - this.props.toothFactor) - this.props.shaftWidth)/2, 0,
    sine_curve(this.props.shaftWidth, -this.half_circle()),
  ].join(" ")

  front_edge = (double) => {
    const width = double ? this.width() * (1 - this.props.toothFactor) : this.width();
    return [
      "M", width/2, this.half_circle(),
      sine_curve(-width, this.half_circle()),
      'l', 0, this.props.thickness,
      sine_curve(width, -this.half_circle()),
      'l', 0, -this.props.thickness,
    ].join(" ");
  }

  single_helix = () => (
    <g className="helix">
      <rect x={-this.width()/2} y="0" width={this.side_width()} height={this.props.thickness} />
      <path d={this.curve()} clipPath={`url(#top-edge-${this.props.i})`} />
      <path d={this.curve(true)} clipPath={`url(#bottom-edge-${this.props.i})`} />
      <path d={this.front_edge()} />
    </g>
  )

  double_helix = () => (
    <g className="helix">
      <rect x={-this.width()/2} y="0" width={this.side_width()} height={this.props.thickness} />
      <path d={this.double_curve()} clipPath={`url(#top-edge-${this.props.i})`} />
      <path d={this.double_curve(true)} clipPath={`url(#bottom-edge-${this.props.i})`} />
      <rect x={this.width()/2 * (1 - this.props.toothFactor)} y={this.height()/2} width={this.width()/2 * this.props.toothFactor} height={this.props.thickness} />
      <path d={this.front_edge(true)} />
    </g>
  )

  clip_paths = () => (
    <defs>
      <clipPath id={`top-edge-${this.props.i}`}>
        <rect x={this.props.shaftWidth/2} y="0" width={this.width()} height={this.height()} />
        <rect x={-this.width()/2} y={this.height()/2} width={this.width()} height={this.height()} />
      </clipPath>
      <clipPath id={`bottom-edge-${this.props.i}`}>
        <rect x={-this.width()/2} y="0" width={this.side_width()} height={this.height()} />
        <rect x={-this.width()/2} y={this.height()*3/4} width={this.width()} height={this.height()} />
      </clipPath>
    </defs>
  )

  flip = () => this.props.i % 2 == 0 ? "" : "scale(-1, 1)";

  render() {
    const position = this.props.positionFromTop * this.props.factorY - this.height();
    return (
      <g transform={"translate(0, " + position + ") " + this.flip()}>
        {this.clip_paths()}
        {this.dual() ? this.double_helix() : this.single_helix()}
      </g>
    );
  }
}

HelixGraphic.defaultProps = {
  type: 'single',
  shaftWidth: 40,
  thickness: 0.8,
  toothFactor: 0.3,
  widthFactor: 1,
  aspectFactor: 0.25
};
