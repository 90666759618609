import SoilActions from 'actions/SoilActions';

const clearBulkSoil = () => SoilActions.setBulkSoil(null);

export function bulkSoilConfirm(bulkSoil, action=clearBulkSoil) {
  if (!bulkSoil || window.confirm("This action will erase all bulk data."))  {
    action();
  }
};

