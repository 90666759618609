import { IonList, IonItem, IonIcon } from 'reactionic';
import ProjectActions from 'actions/ProjectActions';
import UserStore from 'stores/UserStore';
import ReportButton from './ReportButton';

const deleteProject = (project) => {
  if (window.confirm(`Are you sure you want to delete '${project.name}'`)) {
    ProjectActions.deleteProject(project);
  }
};

export default function ProjectMenu({project}) {
  const {global_editor=false} = UserStore.getInfo();
  return (
    <div className="content">
      <IonList>
        <ReportButton project={project}/>
        {(global_editor || project.owned) && <IonItem iconLeft link={`/projects/${project.id}/settings`}>
          <IonIcon icon="ios-cog-outline"/>
          Edit Project
        </IonItem>}
        <IonItem iconLeft link={`/projects/${project.id}/copy`}>
          <IonIcon icon="ios-copy-outline"/>
          Copy Project
        </IonItem>
        {project.id && project.owned && (
          <IonItem iconLeft onClick={() => deleteProject(project)} customClasses="assertive">
            <IonIcon icon="ios-trash"/>
            Delete Project
          </IonItem>
        )}
      </IonList>
    </div>
  );
}
