import classnames from 'classnames';
import { IonButton } from 'reactionic';

export default function Segment({value, options, onChange}) {
  return (
    <div className="segment">
      {options.map((option) => (
        <IonButton key={option}
          onClick={() => onChange(option)}
          customClasses={classnames({'button-positive': value === option})}
        >
          {option}
        </IonButton>
      ))}
    </div>
  );
}
