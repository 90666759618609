import { renderToStaticMarkup } from 'react-dom/server';
import { IonItem, IonIcon, IonSpinner } from 'reactionic';
import API from 'API';
import { withOnline } from 'components/helpers/online-helper';
import Report from './Report';


class ReportButton extends React.Component {
  state = {loading: false}

  download = () => {
    const {online, project} = this.props
    if (!online) {
      return alert("You must be online to download a report.");
    }
    const markup = renderToStaticMarkup(<Report project={project} />);
    const {prefix:pile_type, pitch} = project.settings.pile
    this.setState({loading: true});
    API.downloadReport({id: project.id, markup, pile_type, pitch, callback: this.openToPdf});
  }

  openToPdf = ({url}) => {
    window.location.href = url;
    this.setState({loading: false});
  }

  render() {
    if (!this.props.online) return null;
    return (
      <IonItem iconLeft onClick={this.download}>
        {this.state.loading ? (
          <IonSpinner icon="ios-small" customClasses="icon" />
        ) : (
          <IonIcon icon="ios-download"/>
        )}
        Download Report (pdf)
      </IonItem>
    );
  }
}

export default withOnline(ReportButton);
