import PropTypes from 'prop-types';
import { hashHistory } from 'react-router';
import { IonList, IonItem, IonIcon } from 'reactionic';
import { showPopup } from 'lib/ionic-utils';

import UserStore from 'stores/UserStore';
import BooleanInput from 'components/helpers/boolean-input';
import NumberInput from 'components/helpers/number-input';
import HelpMessage from 'components/helpers/HelpMessage';
import { displayDiameterError } from 'components/helpers/error-helper';
import SoilTestUnitSelector from './loads/SoilTestUnitSelector';
import LoadUnitSelector from './loads/LoadUnitSelector';
import SafetyFactor from './loads/SafetyFactor';
import PileCount from './loads/PileCount';
import LoadInput from './loads/LoadInput';
import {
  safetyFactor,
  includeShaftFriction,
  reduceShaftFriction,
  loadDesign,
  soilTestUnits,
  standardGroutDiameters,
  postGroutLead
} from 'config/help-text';

export default class Loads extends React.Component {
  static contextTypes = {
    ionUpdatePopup: PropTypes.func
  }

  showHelp = (helpText) => () => showPopup(this.context, <HelpMessage content={helpText}/>)

  nextPage = () => {
    const {id} = this.props.project;
    hashHistory.push({pathname: `/projects/${id}/soil`});
  }

  render() {
    const {settings, data} = this.props.project;
    const {global_editor=false} = UserStore.getInfo();
    const readonly = this.props.readonly || (!this.props.project.owned && !global_editor);

    return (
      <IonList customClasses="item-input-right">
        <SoilTestUnitSelector selected={settings.testUnit} system={settings.system} checked={settings.soilTestUnits} help={this.showHelp(soilTestUnits)}/>
        <LoadUnitSelector settings={settings} />
        <SafetyFactor safetyFactor={data.safetyFactor} readonly={readonly} help={this.showHelp(safetyFactor)}/>
        <BooleanInput label="Include Shaft Friction"
          property="includeShaftFriction"
          value={settings.includeShaftFriction}
          help={this.showHelp(includeShaftFriction)} />
        <BooleanInput label="Reduce Shaft Friction"
          property="reduceShaftFriction"
          value={settings.reduceShaftFriction}
          help={this.showHelp(reduceShaftFriction)} />
        <BooleanInput label="Grouted Shaft?"
          property="groutedShaft"
          value={settings.groutedShaft} />
        {settings.groutedShaft && (
          <NumberInput label="Grouted Diameter"
            help={this.showHelp(standardGroutDiameters)}
            property="groutDiameter"
            value={settings.groutDiameter ?? ''}
            unit={settings.smallLengthUnit}
            error={settings.groutedShaft ? displayDiameterError(this.context, settings.pile.diameter, settings.groutDiameter, data) : false}
            min={0}/>
        )}
        {settings.groutedShaft && settings.postGroutLead && (
          <BooleanInput label={
              <span>Post Grout Lead? <span style={{color: 'red'}}>No Longer Available</span></span>
            }
            property="postGroutLead"
            value={settings.postGroutLead}/>
        )}
        <IonItem divider onClick={ this.showHelp(loadDesign) }>
          Required Capacity <IonIcon icon="help-circled"/>:
        </IonItem>
        <LoadInput loads={data.loads} loadType='compression' readonly={readonly} unit={settings.forceUnit}/>
        <LoadInput loads={data.loads} loadType='tension' readonly={readonly} unit={settings.forceUnit}/>
        <PileCount pileCount={data.pileCount} readonly={readonly}/>
        <input ref="formEnd" style={{opacity: 0}} onFocus={this.nextPage} />
      </IonList>
    );
  }
}
