import ProjectSettingsActions from 'actions/ProjectSettingsActions';
import { titleize } from 'lib/text';

export default function LoadInput({unit, loadType, loads, readonly}) {
  const changeLoad = ({target}) => {
    const baseValue = unit.toBase(target.value);
    ProjectSettingsActions.setLoad({[loadType]: baseValue});
  };

  const displayValue = _.round(unit.toDisplay(loads[loadType]), 2) || '';

  return (
    <label className='item item-input'>
      <span className="input-label">{titleize(loadType)}:</span>
      {readonly ? (
        <span className="readonly-value">{displayValue}</span>
      ) : (
        <input type="number" min="0" step="1" value={displayValue} onChange={changeLoad} />
      )}
      <span className="unit">{unit.abbreviation}</span>
    </label>
  );
}
