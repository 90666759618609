import { IonItemToggle, IonIcon } from 'reactionic';

import ProjectSettingsActions from 'actions/ProjectSettingsActions';

export default function({value, label, property, help}) {
  const onChange = (value) => ProjectSettingsActions.set({[property]: value});
  const toggleLabel = help ? (
    <span className="clickable" onClick={help}>
      {label} <IonIcon icon="help-circled"/>:
    </span>
  ) : label;
  return (
    <IonItemToggle
      color="positive"
      label={toggleLabel}
      checked={value}
      handleChange={onChange}/>
  );
}
