export default ({width, height, maxDim, children}) => {
  const viewBox = [0, 0, width, height].join(" ");
  const aspect = Math.round(height/width*100);

  return (
    <div className="responsive-svg" style={{paddingBottom: `${aspect}%`}}>
      <svg viewBox={viewBox} {...maxDim} style={maxDim} preserveAspectRatio="xMinYMin meet" xmlns="http://www.w3.org/2000/svg">
        {children}
      </svg>
    </div>
  );
}
