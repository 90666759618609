import {abbreviateNumber} from 'lib/number';

export default function GridLabel({x, y, factor, unit, GRAPH_DIMENTIONS}) {
  const {OFFSET_X, OFFSET_Y} = GRAPH_DIMENTIONS;
  if (!x && !y) return null;
  const [value, valueOffset, marginOffset, alignment] = x ?
    [x, OFFSET_X, OFFSET_Y, "middle"] :
    [y, OFFSET_Y, OFFSET_X, "end"];

  const coordValue = valueOffset + unit.toBase(value * factor);
  const coordMargin = marginOffset - 2;
  const [coordX, coordY] = x ? [coordValue, coordMargin] : [coordMargin, coordValue];

  return (
    <text x={coordX} y={coordY} textAnchor={alignment}>
      {abbreviateNumber(value)}{y ? unit.abbreviation : ''}
    </text>
  );
}
