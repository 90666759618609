export function showPopup(context, template, title='Help', buttonText='Close', {customClasses}={}) {
  context.ionUpdatePopup({
    popupType: 'show',
    title, template, customClasses,
    buttons: [{
      text: buttonText,
      type: 'button-positive'
    }]
  })
};
