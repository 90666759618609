import store from 'store';

export const withOnline = (Component) => class WithOnlineWrapper extends React.Component {
  state = {
    online: store.getState().online
  }

  componentDidMount() {
    this.unsubscribe = store.subscribe(() => {
      const {online} = store.getState();
      if (online !== this.state.online) this.setState({online});
    });
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  render() {
    return <Component {...this.props} {...this.state}/>;
  }
}
