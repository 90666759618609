const rollbarConfig = {
  enabled: !!process.env.ROLLBAR_POST_CLIENT_ITEM,
  accessToken: process.env.ROLLBAR_POST_CLIENT_ITEM,
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    client: {
      javascript: {
        source_map_enabled: true,
        code_version: CODE_VERSION,
        guess_uncaught_frames: true,
      }
    },
    environment: process.env.NODE_ENV || 'development',
    app_version: VERSION
  }
}

export default rollbarConfig;
