import { set } from 'lodash/fp';
import { CURRENT_SCHEMA_VERSION } from 'lib/constants';
import { normalizeProject } from './normalize';

const transformValues = (object, transformation) => (
  _.transform(object, (result, value, key) => result[key] = transformation(value), {})
)

const migrations = [
  function v1(project) {
    const setSchema = set('data.schema', 1);
    return _.flow([fixkN, setSchema])(project);

    function fixkN(project) {
      const isUsingKN = _.get(project, 'settings.forceUnit.value') === 'kn';
      if (!isUsingKN) return project;
      const convert = (value) => value / 0.100361135657 / 8.896443231;
      const convertedLoads = transformValues(_.get(project, 'data.loads'), convert);
      return set('data.loads', convertedLoads, project);
    }
  },
]

export default function migrate(project) {
  const projectVersion = (project.data ?? {schema: 'unknown'}).schema;
  if (projectVersion === CURRENT_SCHEMA_VERSION || projectVersion === 'unknown') {
    return normalizeProject(project);
  }
  const migrationsToRun = _.flow(_.slice(migrations, projectVersion, CURRENT_SCHEMA_VERSION));
  return normalizeProject(migrationsToRun(project));
}
