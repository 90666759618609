import { DEFAULT_PROJECT_STATUS as DEFAULT} from 'reducers/projects/defaults';

export default function projectStatus(state={}, action) {
  switch(action.type) {
    case 'RECEIVED_PROJECT':
      if (!_.isEmpty(action.project.errors)) {
        return {
          ...state,
          error: true,
          errorMsg: action.project.errors,
          lastUpdateSuccess: false
        };
      } else if (action.tempId && action.tempId !== state.projectID) {
        return state;
      } else {
        return {
          ...DEFAULT,
          projectID: action.project.id,
          lastUpdateSuccess: true
        };
      }
    case 'PROJECT_SUBMITTED':
      return {...state, error: false, errorMsg: null, lastUpdateSuccess: false};
    case 'PROJECT_ERROR':
      return {...state, error: true, errorMsg: action.errors, lastUpdateSuccess: false};
    case 'CLEAR_ERRORS':
      return {...DEFAULT, projectID: state.projectID};
    case 'SET_CURRENT_PROJECT':
      return {...DEFAULT, projectID: action.id};
    case 'MARK_PROJECT_DELETED':
      if (state.projectID != action.projectId) return state;
    case 'RESET':
      return DEFAULT;
    default:
      return state;
  }
}
