const PhoneMagnum = () => (
  <div>
    <p>Office Hrs: Mon-Fri 8-5 EST</p>
    <a href='tel:1-800-822-PIER'>1-800-822-PIER(7437)</a>
  </div>
);

const ShaftMessage = () => (
  <div>
    <h5>Magnum helical pile shaft size.</h5>
    <span>See Magnum <a href="http://www.magnumpiering.com/products/product-catalog/">Product Catalog</a> for more information.</span>
  </div>
);

module.exports = {
  soilTestUnits:
    `Select the soil test units from your borings:
    SPT70 = Standard Penetration Test 70% Efficient
    SPT60 = Standard Penetration Test 60% Efficient
    SPT90 = Standard Penetration Test 90% Efficient
    CPT = Cone Penetrometer Test Tip Resistance
    STR = Undrained Shear Strength
    UC = Unconfined Compressive Strength`,
  includeShaftFriction:
    `Add friction along pile shaft to total pile capacity. Often negligible for small diameter shafts.
    Can be significant for larger diameter pile shafts and for grouted shafts.`,
  reduceShaftFriction:
    `Reduce shaft friction by 50% to account for disturbances such as multiple external couplers or in sensitive soils.`,
  shaftFriction:
    `Add friction along pile shaft to total pile capacity. Often negligible for small diameter shafts.
    Can be significant for larger diameter pile shafts and for grouted shafts.
    Shaft friction may be reduced by 50% to account for disturbances such as multiple external couplers or in sensitive soils.`,
  includeFill: 'Include shaft friction in fill/sensitive soils',
  includeBedrock: 'Include shaft friction in bedrock',
  safetyFactor:
    `Default is 2.0 for ASD and 1.5 for LRFD. Pile head deflections of 1/2" (1.2 cm) are common at nominal loads.
    Increase safety factor to reduce pile head deflections. FS=2.5 is common when soil boring data are limited.`,
  loadDesign:
    `Enter required pile capacity (commonly referred to as "allowable" in ASD).
    For engineers, the required capacity corresponds to the maximum factored nominal loads combinations for ASD or the maximum factored service loads combinations for LRFD.`,
  leadSegment:
    `Select a trial lead section from the filtered list. Check results.
    Adjust as necessary to achieve satisfactory capacity. Please note the following nomenclature:`,
  phoneMagnum: <PhoneMagnum />,
  shaftSize: <ShaftMessage/>,
  standardGroutDiameters:
    `Standard Grout Column diameters:
    MH325, Grout Diam.= 8" (20 cm)
    MH431, Grout Diam.= 10" (25 cm)
    MH536, Grout Diam.= 14" (35 cm)
    MH547, Grout Diam.= 16" (41 cm)
    MH939, Grout Diam.= 22" (56 cm)
    Other grout diameters may not be available.`,
  postGroutLead:
    'Post grouting lead section improves soil around helices and provides additional bond strength.  A minimum ' +
    'pressure of 300 psi must be held for sufficient time to allow consolidation.  This technique is novel; multiple load tests ' +
    'should be conducted to evaluate effectiveness of post grouting for each subsurface profile and pile type.'
}
