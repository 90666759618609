import PropTypes from 'prop-types';
import { IonIcon, IonSpinner, IonTabs, IonItemToggle, IonButton } from 'reactionic';

import HelpText from 'config/help-text';
import { showPopup } from 'lib/ionic-utils';
import { scrollBackToTop } from 'lib/view_helper';
import { helixHeight } from 'lib/custom-helix';
import upgradeOldProjects from 'helpers/project-settings-upgrade';
import {smallUnitDisplay, smallUnitDisplayAsBig, pitchLabelForUnit, lengthLabelForUnit} from 'helpers/unit-display';
import degreeUnits from 'config/degree-units';

import store from 'store';
import ProductStore from 'stores/ProductStore';
import PileSelectionActions from 'actions/PileSelectionActions';
import ProjectSettingsActions from 'actions/ProjectSettingsActions';

import { withOnline } from 'components/helpers/online-helper';
import preventDefault from 'components/helpers/event';
import HelpMessage from 'components/helpers/HelpMessage';
import NumberInput from 'components/helpers/number-input';
import BooleanInput from 'components/helpers/boolean-input';
import Segment from 'components/helpers/Segment';
import { displayDiameterError, displayDiscontinuedWarning } from 'components/helpers/error-helper';

import PileFilterSelector from './pile-selection/PileFilterSelector';
import DualCutterFilter from './pile-selection/DualCutterFilter';
import Lead from './pile-selection/Lead';
import CustomHelix from './pile-selection/CustomHelix';
import { standardGroutDiameters, shaftFriction, includeFill, includeBedrock } from 'config/help-text';

import SHAFT_DESIGNATION_IMAGE_FILE from 'images/shaft-designation.png';

const GALVANIZATION_TYPES = ["Non-Galvanized", "Galvanized"];
const PITCH_OPTIONS = [3.0, 6.0];

const soilPresent = (soilTypes, soil) => _.includes(_.map(soilTypes, 'name'), soil);

const prefixFromSku = (sku) => {
  const match = sku.match(/^[A-Z]+(\d+BR?)/);
  return match ? `MH${match[1]}` : '';
}
function leadOptions(leadList=[]) {
  return _.uniqBy(_.orderBy(leadList, 'length'), ({helixConfig}) => _.join(helixConfig));
}
const maxBlowCountDepth = (blowCounts=[]) => blowCounts.slice(1).indexOf(0) * 5;
const productTypeForCapacity = (productTypes, minCapacity) => (
  _.minBy(productTypes.filter((t) => t.capacity >= minCapacity), 'capacity')
) || (
  _.maxBy(productTypes, 'capacity')
);
const defaultPitchFor = ({pitches=[], diameter}) => (
  pitches.length === 1 ? pitches[0] : (diameter >= 5 ? 6 : 3)
);

class PileOptions extends React.Component {
  constructor(props) {
    super(props);
    const productTypes = this.getProductTypes();
    if (_.isEmpty(ProductStore.getProductTypes())) PileSelectionActions.getProductTypes();

    const {blowCounts} = this.props.data || {};
    const {lengthUnit, pile={}} = this.props.settings;

    const defaultLength = Math.max(maxBlowCountDepth(blowCounts), 12);

    if (!this.props.readonly) upgradeOldProjects(pile, this.props.data.pileSegments);
    const leadSegment = _.first(this.props.data.pileSegments);

    this.state = {
      productTypes,
      pileLength: _.round(lengthUnit.toDisplay(pile.pileLength || defaultLength), 1),
      loading: false,
      selectedProductType: pile.prefix ? _.find(productTypes, {prefix: pile.prefix}) : this.defaultProductType(),
      activeTab: leadSegment && leadSegment.custom ? "custom" : "preset"
    };
  }

  static contextTypes = {
    ionUpdatePopup: PropTypes.func
  }

  defaultProductType = () => {
    const productTypes = this.state?.productTypes || this.getProductTypes();
    const {loads: {compression=0, tension=0}={}} = this.props.data || {};
    const minCapacity = Math.max(tension, compression);

    const defaultProductType = productTypeForCapacity(productTypes, minCapacity);
    if (defaultProductType) defaultProductType.pitch = _.get(defaultProductType, 'pitches.0');

    return defaultProductType;
  }

  componentDidMount() {
    this.unsubscribeProductStore = store.subscribe(() => {
      const productTypes = this.getProductTypes();
      const nonce = _.get(store.getState(), 'products.requestNonce');

      this.setState(({selectedProductType, requestNonce}) => ({
        productTypes,
        loading: requestNonce !== nonce,
        selectedProductType: selectedProductType || productTypes[0]
      }));
    });
    !this.props.readonly && this.fetchLeads(this.state.selectedProductType);
  }

  componentDidUpdate(prevProps) {
    const {settings:newSettings, pileOptions:newPileOptions} = this.props;
    if (newPileOptions.prefix != prevProps.pileOptions.prefix || newPileOptions.pitch != prevProps.pileOptions.pitch) {
      this.fetchLeads();
    }
    if (newSettings.lengthUnit != prevProps.settings.lengthUnit) {
      this.setState({pileLength: _.round(newSettings.lengthUnit.toDisplay(newSettings.pile.pileLength), 1)});
    }
  }

  componentWillUnmount() {
    this.unsubscribeProductStore();
  }

  getProductTypes = () => {
    const leadSegment = _.first(this.props.data.pileSegments);
    const leadProductType = leadSegment ? {...leadSegment, prefix: prefixFromSku(leadSegment.sku), discontinued: true} : null;
    return _.uniqBy(_.compact([...ProductStore.getProductTypes(), leadProductType]), 'prefix');
  }

  setProductType = (prefix) => {
    const {pileOptions, settings: {groutDiameter, groutedShaft}} = this.props;
    const leadList = ProductStore.getLeadList(pileOptions) || [];
    const selectedProductType = _.find(this.state.productTypes, {prefix}) || this.defaultProductType();

    PileSelectionActions.setFilters(selectedProductType);

    if (selectedProductType) {
      PileSelectionActions.setFilters({pitch: defaultPitchFor(selectedProductType)});

      if (!_.includes(selectedProductType.lengths, parseInt(pileOptions.length, 10))) {
        PileSelectionActions.setFilters({length: ''});
      }
      if (!groutDiameter || !groutedShaft) {
        ProjectSettingsActions.set({groutDiameter: _.ceil(selectedProductType.diameter + 2)});
      }
    }

    this.setState({selectedProductType});
    if (_.get(this.leadSegment(), 'custom')) this.clearHelixSelection();
  }

  leadSegment = () => _.first(this.props.data.pileSegments)

  clearHelixSelection = () => {
    PileSelectionActions.resetPileSegments();
    PileSelectionActions.setFilters({helixConfig: null});
  }

  fetchLeads = (options={}) => {
    const requestNonce = _.uniqueId('product_request_');
    PileSelectionActions.getSegments({...this.state.selectedProductType, ...options}, requestNonce);

    this.setState({loading: true, requestNonce});
  }

  setPileLength = ({target: {value}}) => {
    const {lengthUnit, aboveGrade} = this.props.settings;
    if (this.leadSegment()) {
      const lead = this.leadSegment();
      const gradeOffset = Math.max(-aboveGrade, 0);
      const minLength = _.ceil(lengthUnit.toDisplay(
        lead.custom ? helixHeight(lead) : lead.shaftLength
      )) + gradeOffset;
      value = _.clamp(value, minLength, _.floor(lengthUnit.toDisplay(200)));
    }

    this.setState({pileLength: value});

    const pileLength = _.round(lengthUnit.toBase(value));
    store.dispatch(PileSelectionActions.setLength(pileLength));
  }

  setLead = (lead, custom=false) => {
    const {lengthUnit, aboveGrade} = this.props.settings;
    if (!custom) scrollBackToTop('content');
    const gradeOffset = Math.max(-aboveGrade, 0);
    const newPileLength = Math.max(lengthUnit.toBase(this.state.pileLength) - gradeOffset, helixHeight(lead)) + gradeOffset;
    this.setState({pileLength: _.round(lengthUnit.toDisplay(newPileLength), 1)});
    store.dispatch(PileSelectionActions.setLength(newPileLength));
    store.dispatch(PileSelectionActions.setLead(lead, newPileLength));
  }

  showHelp = (helpKey, imageUrl=null) => () => (
    showPopup(this.context, <HelpMessage content={helpKey} image={imageUrl}/>)
  )

  selectTab = (tabname) => preventDefault(() => this.setState({activeTab: tabname}))

  render() {
    const {data, settings, readonly, pileOptions:selected} = this.props;
    const {smallLengthUnit, lengthUnit, groutedShaft, groutDiameter, aboveGrade, batteredPile, batterAngle} = settings;
    const {selectedProductType={}, productTypes, pileLength, activeTab} = this.state;

    const loading = this.state.loading && this.props.online;
    const availablePitches = _.pull(_.sortBy(selectedProductType.pitches || PITCH_OPTIONS), 0);
    const leadSegment = this.leadSegment();
    const leadList = ProductStore.getLeadList({...selected, ...selectedProductType, pileLength}) || [];
    const adminOnly = selectedProductType?.admin_only;
    return (
      <div className="padding">
        <div className="list">
          <h3>Pile</h3>
          {adminOnly && <p className="admin-alert"><IonIcon icon="alert-circled" />You are viewing an admin only product.</p>}
          <PileFilterSelector label="Shaft"
            filter="prefix"
            readonly={readonly}
            filters={selectedProductType}
            options={["Auto Select", ..._.map(productTypes, 'prefix')]}
            onChange={({target: {value}}) => this.setProductType(value)}
            allowBlank={false}
            error={
              groutedShaft ? (
                displayDiameterError(this.context, selected.diameter, groutDiameter, data)
              ) : (
                selectedProductType.discontinued ? displayDiscontinuedWarning(this.context) : null
              )
            }
            help={this.showHelp(HelpText.shaftSize, SHAFT_DESIGNATION_IMAGE_FILE)}/>
          <PileFilterSelector label="Pitch"
            filter="pitch"
            readonly={readonly}
            filters={selected}
            options={availablePitches}
            optionDisplay={pitchLabelForUnit(smallLengthUnit)}
            allowBlank={false}/>
          <PileFilterSelector label="Coating"
            filter="coating"
            readonly={readonly}
            options={GALVANIZATION_TYPES}
            filters={selected}
            onChange={({target: {value}}) => PileSelectionActions.setFilters({coating: value})}
            allowBlank={false}/>
          <BooleanInput label="Grouted Shaft?"
            property="groutedShaft"
            value={settings.groutedShaft} />
          {settings.groutedShaft && (
            <NumberInput label="Grouted Diameter"
              help={this.showHelp(standardGroutDiameters)}
              property="groutDiameter"
              value={settings.groutDiameter ?? ''}
              unit={settings.smallLengthUnit}
              error={settings.groutedShaft ? displayDiameterError(this.context, settings.pile.diameter, settings.groutDiameter, data) : false}
              min={0}/>
          )}
          <h3>Calculation</h3>
          <label className="item item-input item-input-right item-responsive-wrap">
            <span className="input-labelclickable" onClick={this.showHelp(shaftFriction)}>
              Shaft Friction  <IonIcon icon="help-circled"/>:
            </span>
            {settings.groutedShaft ? (
              <span className="tag tag-positive">Included for grouted shafts</span>
            ) : (
              <Segment
                value={settings.includeShaftFriction ? (settings.reduceShaftFriction ? 'Reduce' : 'Include') : 'Off'}
                options={['Include', 'Reduce', 'Off']}
                onChange={(value) => ProjectSettingsActions.setFriction(value)}/>
            )}
          </label>
          {(settings.includeShaftFriction || settings.groutedShaft) && <>
            {soilPresent(data.soilTypes, 'sensitive') && (
              <BooleanInput label="Include Fill/Sensitive?"
                help={this.showHelp(includeFill)}
                property="includeFillSensitive"
                value={settings.includeFillSensitive} />
            )}
            {soilPresent(data.soilTypes, 'bedrock') && (
              <BooleanInput label="Include Bedrock?"
                help={this.showHelp(includeBedrock)}
                property="includeBedrock"
                value={settings.includeBedrock} />
            )}
          </>}
          <h3>Depth</h3>
          <label className="item item-input item-input-right">
            <span className="input-label">Pile Depth:</span>
            <input type="number" disabled={readonly} value={pileLength} onChange={this.setPileLength} min={1} max={200}/>
            <span className="unit">{lengthUnit.label}</span>
          </label>
          <NumberInput label="Pile Above(+)/Below(-) Grade"
            property="aboveGrade"
            value={aboveGrade}
            unit={lengthUnit}
            className="item-responsive-wrap"
            readonly={readonly}/>
          <IonItemToggle
            color="positive"
            label={<span className="clickable">Battered Pile:</span>}
            readonly={readonly}
            checked={batteredPile}
            handleChange={(checked) => ProjectSettingsActions.set({batteredPile: checked})}/>
          {batteredPile && (
            <NumberInput label="Batter Angle"
              property="batterAngle"
              value={batterAngle || 0}
              unit={degreeUnits['degrees']}
              max={60}
              min={-60}
              readonly={readonly}/>
          )}

          <h3>Helix Arrangement:</h3>
          {readonly ? (leadSegment && (
            <div>
              <ul className="list">
                <li className="item item-button-right">
                  {leadSegment.helixConfig}{leadSegment.custom ? " (custom)" : ""}
                </li>
              </ul>
            </div>
          )) : (
            <div>
              {selected.helixConfig && (
                <ul className="list">
                  <li className="item item-button-right">
                    {selected.helixConfig}{leadSegment && leadSegment.custom ? " (custom)" : ""}
                    {!loading && !leadSegment && (
                      <span className="lead-error">This Lead is incompatible with your selected options</span>
                    )}
                    <button className="button button-energized" onClick={this.clearHelixSelection}>
                      <i className="icon ion-minus"/>
                    </button>
                  </li>
                </ul>
              )}
              <h4>
                Select a {leadSegment && 'Different'} Helix Arrangement:
              </h4>

              {!loading && !_.isEmpty(leadList) && (
                <IonTabs tabsTop={true} customClasses="tabs-striped tabs-top tabs-color-positive subtabs margin-after">
                  <a className={`tab-item ${activeTab === "preset" ? "active" : ""}`} href="#" onClick={this.selectTab("preset")}>Preset Arrangement</a>
                  <a className={`tab-item ${activeTab === "custom" ? "active" : ""}`} href="#" onClick={this.selectTab("custom")}>Custom</a>
                </IonTabs>
              )}
              {activeTab === "preset" && <>
                <DualCutterFilter checked={selected.includeDualCutter}/>
                <br/>
                {loading ? (
                  <IonSpinner icon="ios-small" />
                ) : (
                  (_.isEmpty(leadList) || !pileLength) ? (
                    <div className="blank">
                      No products matching this filter were found.
                      Please change your search filters.
                    </div>
                  ) : (
                    <ul>
                      {leadOptions(leadList).map((lead, i) => (
                        <Lead key={`${lead.key}${i}`} lead={lead} setLead={this.setLead}/>
                      ))}
                    </ul>
                  )
                )}
              </>}
              {activeTab === "custom" && (
                <CustomHelix
                  lead={leadSegment}
                  leadPrototype={_.first(leadList)}
                  helixSpacing={_.get(selectedProductType, ['helix_spacings', selected.pitch])}
                  pileLength={pileLength}
                  clearHelixSelection={this.clearHelixSelection}
                  setLead={this.setLead}/>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default withOnline(PileOptions);
