export default {
  "inches": {
    "system": "english",
    "value": "inches",
    "label": "inches",
    "abbreviation": `"`,
    "toBase": value => value/1,
    "toDisplay": value => value/1,
    "toBigUnit": value => value/12
  },
  "centimeters": {
    "system": "metric",
    "value": "centimeters",
    "label": "centimeters",
    "abbreviation": "cm",
    "toBase": value => value/2.54,
    "toDisplay": value => value*2.54,
    "toBigUnit": value => value/10
  }
}
