export default {
  "tons": {
    "value": "tons",
    "label": "tons",
    "abbreviation": "tons",
    "toBase": function(value) {
      return value/1;
    },
    "toDisplay": function(value) {
      return value/1;
    }
  },
  "ftlbs": {
    "value": "ftlbs",
    "label": "foot-pounds",
    "abbreviation": "ft-lbs",
    "toBase": function(value) {
      return value/2000;
    },
    "toDisplay": function(value) {
      return value*2000;
    }
  },
  "Nm": {
    "value": "Nm",
    "label": "Newton-meters",
    "abbreviation": "N-m",
    "toBase": function(value) {
      return value/(2000*1.36);
    },
    "toDisplay": function(value) {
      return value*(2000*1.36);
    }
  },
  "kips": {
    "value": "kips",
    "label": "kips",
    "abbreviation": "kips",
    "toBase": function(value) {
      return value*0.5;
    },
    "toDisplay": function(value) {
      return value/0.5;
    }
  },
  "kn": {
    "value": "kn",
    "label": "kilonewtons",
    "abbreviation": "kN",
    "toBase": function(value) {
      return value/8.896443231;
    },
    "toDisplay": function(value) {
      return value*8.896443231;
    }
  },
  "mtons": {
    "value": "mtons",
    "label": "metric tons",
    "abbreviation": "mtons",
    "toBase": function(value) {
      return value*1.1023;
    },
    "toDisplay": function(value) {
      return value/1.1023;
    }
  }
}
