import torqueUnitFor from 'config/torque-units';
import { capacities, torques, requiredInstallTorque as getRequiredInstalTorque } from 'lib/capacity-calculations';
import { toSignificant } from 'lib/number';
import { titleize } from 'lib/text';
import maxGraphDepth from 'lib/graph-depth';

import SwipeCarousel from 'components/helpers/SwipeCarousel';
import DepthGraph from 'components/graph/DepthGraph';

const requiredTorqueLabel = ({torqueUnit, requiredInstallTorque}) => (
  `Required Install Torque = ${toSignificant(torqueUnit.toDisplay(requiredInstallTorque), 3).toLocaleString()}`
);

export default class CapacityCalcResults extends React.Component {
  static defaultProps = {
    initialDisplay: 'torque'
  }

  state = {
    display: this.props.initialDisplay
  }

  switchGraph = (graph) => {
    this.setState({display: graph});
  }

  maxTorque = () => {
    const {pileSegments} = this.props.project.data;
    const lead = _.find(pileSegments, {lead_extension: 'Lead'});
    return lead ? lead.max_torque : 0;
  }

  graphData = () => {
    const {project} = this.props;
    const {loads: {compression, tension}} = project.data;
    const {forceUnit, lengthUnit, groutedShaft} = project.settings;
    const torqueUnit = torqueUnitFor(lengthUnit);

    if (this.state.display == 'torque') {
      const requiredInstallTorque = getRequiredInstalTorque(project)
      const torqueGuides = {
        'Manufacturer Torque Rating': this.maxTorque()/2000
      };
      if (!groutedShaft && (compression > 0 || tension > 0)) {
        torqueGuides[requiredTorqueLabel({requiredInstallTorque, torqueUnit})] = requiredInstallTorque;
      }
      return {
        data: torques(project),
        envelope: [torques(project, -1), torques(project, 1)],
        guides: torqueGuides,
        unitX: torqueUnit
      };
    } else {
      return {
        data: capacities(project),
        envelope: [capacities(project, -1), capacities(project, 1)],
        guides: {
          'Required Compression Capacity': compression,
          'Required Tension Capacity': tension
        },
        unitX: forceUnit
      };
    }
  }

  render() {
    const {maxDim, project: {data, settings: {forceUnit, lengthUnit}}} = this.props;
    const graphs = [
      {value: 'torque', title: `Torque (${torqueUnitFor(lengthUnit).abbreviation})`},
      {value: 'capacity', title: `Allowable Capacity (${forceUnit.abbreviation})`}
    ];
    return (
      <div>
        <SwipeCarousel options={graphs} value={this.state.display} onChange={this.switchGraph} />
        {data.pileSegments.length > 0 && (
          <DepthGraph {...this.graphData()}
            unitY={lengthUnit}
            maxDepth={maxGraphDepth({data})}
            baseScale="25"
            maxDim={maxDim} />
        )}
      </div>
    );
  }
}
