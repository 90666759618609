import { IonIcon } from 'reactionic';
import GenericOption from 'components/helpers/GenericOption';
import PileSelectionActions from 'actions/PileSelectionActions';

export default function PileFilterSelector({
  label, filters={}, filter, options, optionDisplay=_.identity, onChange,
  allowBlank=true, help, readonly, error, extra
}) {
  const setFilter = ({target: {value}}) => {
    PileSelectionActions.setFilters({[filter]: parseInt(value, 10) || value});
  };

  return (
    <label className={`item item-input item-select ${readonly ? 'readonly' : ''}`}>
      <span className="input-label" onClick={help}>
        {label}
        {help && <IonIcon icon="help-circled"/>}:
      </span>
      <span className="error-alert" onClick={error}>
        {error && <IonIcon icon="alert-circled" />}
      </span>
      <select disabled={readonly} onChange={onChange || setFilter} value={filters[filter]} className={`${filter}-select`}>
        {allowBlank && <option value="">(all)</option>}
        {_.map(options, (option) => <GenericOption key={option} label={optionDisplay(option)} value={option}/>)}
      </select>
    </label>
  );
}
