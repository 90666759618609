export default function PasswordReset({inputChange, sendPasswordRequest, email}) {
  return (
    <div className="padding">
      <label className="item item-input item-stacked-label">
        <span className="input-label">Email</span>
        <input
          type="email"
          name="email"
          value={email}
          placeholder="yourname@example.com"
          onChange={inputChange} />
      </label>
      <div>
        <button
          className="button button-block button-positive"
          onClick={sendPasswordRequest}
        >
          Reset Password
        </button>
      </div>
    </div>
  );
}
