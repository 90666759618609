import API from 'API';
import store from 'store';
import { Preferences } from '@capacitor/preferences';

const AuthenticationActions = {
  login: API.makeAuthRequest,

  logout() {
    Preferences.clear();
    store.dispatch({type: 'LOGOUT'});
  }
}

global.logout = AuthenticationActions.logout;

export default AuthenticationActions;
