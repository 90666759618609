export default function ChangePassword(props) {
  const {
    changePassword,
    onCodeChange,
    newPassword,
    submittedCode,
    onPasswordChange,
    resetToken
  } = props;

  return (
    <div className="padding">
      {submittedCode !== 'prefilled' && (
        <label className="item item-input item-stacked-label">
          <span className="input-label">6 Digit Security Code</span>
          <input
            type="text"
            name="submittedCode"
            value={submittedCode}
            placeholder="security code from email"
            onChange={onCodeChange} />
        </label>
      )}

      <label className="item item-input item-stacked-label">
        <span className="input-label">New Password</span>
        <input
          type="password"
          name="newPassword"
          value={newPassword}
          placeholder="new password"
          onChange={onPasswordChange} />
      </label>

      <button
        className="button button-block button-positive"
        onClick={changePassword}
      >
        Change Password
      </button>

      <button
        className="button button-block button-dark"
        onClick={resetToken}
      >
        Get New Code
      </button>

      <h3>Password Reset Instructions</h3>
      <p>The application is sending an email to the address provided with a 6 digit security code for the requested password reset.
      Please enter the code provided in the email with your new password and click the 'Change Password' button.</p>
      <p>If you did not receive an email (may take several minutes before arriving), please click the 'Get New Code' to
      re-enter an email address and receive a new security code.</p>
    </div>
  );
}
