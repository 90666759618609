import store from 'store';
import PileSelectionActions from 'actions/PileSelectionActions';

const ensurePrefix = (segment) => {
  if (segment.prefix || !segment.sku) return segment;
  const prefix = _.get(segment.sku.replace('MHL', 'MH').match(/(MH.+BR?)/), 1);
  return {...segment, prefix};
}

export default function upgradeOldProjects(pile, pileSegments=[]) {
  if (pileSegments.length) {
    let pileLength = pile.pileLength;
    if (!pile.pileLength) {
      pileLength = _.reduce(pileSegments, (total, {length}) => total + length, 0)/12;
      store.dispatch(PileSelectionActions.setLength(pileLength));
    }
    if (!pile.prefix || (pileSegments[0].prefix && pile.prefix != pileSegments[0].prefix)) {
      store.dispatch(PileSelectionActions.setLead(ensurePrefix(pileSegments[0]), pileLength));
    } else {
      if (!_.get(store.getState(), 'projectStatus.projectID')) return;
      const productTypes = _.get(store.getState(), 'products.productTypeList', []);
      const selectedProductType = _.find(productTypes, {prefix: pile.prefix});
      if (selectedProductType && pile.wall != selectedProductType.wall) {
        PileSelectionActions.setFilters(selectedProductType);
      }
    }
  }
}
