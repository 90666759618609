import store from 'store';
import Repository from 'repository';

export default {
  includeShaftFriction(boolean) {
    store.dispatch({type: 'INCLUDE_SHAFT_FRICTION', boolean});
    Repository.persistProject();
  },

  reduceShaftFriction(boolean) {
    store.dispatch({type: 'REDUCE_SHAFT_FRICTION', boolean});
    Repository.persistProject();
  },
  setFriction(value) {
    store.dispatch({type: 'INCLUDE_SHAFT_FRICTION', boolean: value !== 'Off'});
    store.dispatch({type: 'REDUCE_SHAFT_FRICTION', boolean: value === 'Reduce'});
    Repository.persistProject();
  },

  setSafetyFactor(factor) {
    store.dispatch({type: 'RECEIVED_NEW_SAFETY_FACTOR', factor});
    Repository.persistProject();
  },

  setPileCount(amount) {
    store.dispatch({type: 'RECEIVED_NEW_PILE_COUNT', amount});
    Repository.persistProject();
  },

  setUnits(unitType) {
    store.dispatch({type: 'RECEIVED_NEW_UNIT_TYPE', unitType});
    Repository.persistProject();
  },

  setTestUnit(testUnitType) {
    store.dispatch({type: 'RECEIVED_NEW_TEST_UNIT_TYPE', testUnitType});
    Repository.persistProject();
  },

  setLoad(load) {
    store.dispatch({type: 'RECEIVED_NEW_LOAD', load});
    Repository.persistProject();
  },

  set(settings) {
    store.dispatch({type: 'SET_PROJECT_SETTINGS', settings});
    Repository.persistProject();
  }
};
