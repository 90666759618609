const config = {
  'development': {
    apiHost: process.env.APIHOST || 'http://localhost:3001'
  },

  'test': {
    apiHost: process.env.APIHOST || 'http://localhost:3001'
  },

  'production': {
    apiHost: process.env.APIHOST || 'https://magnum-piering-staging.herokuapp.com'
  }
};

let instance_config = config[process.env.NODE_ENV] || config.development

export default instance_config;
