import { SplashScreen } from '@capacitor/splash-screen';
import { App as CapApp } from '@capacitor/app';

import { Router, Route, hashHistory, IndexRedirect } from 'react-router';

import API from 'API';
import UserStore from 'stores/UserStore';

import MainLayout from 'components/layouts/MainLayout';
import UnauthenticatedLayout from 'components/layouts/Unauthenticated';
import GenericLayout from 'components/layouts/Generic';
import ProjectsLayout from 'components/layouts/Projects';
import ProjectLayout from 'components/layouts/Project';
import NewProjectLayout from 'components/layouts/NewProject';

import Login from 'components/user/Login';
import Logout from 'components/user/Logout';
import Registration from 'components/user/Registration';
import EditProfile from 'components/user/EditProfile';
import UserDestroy from 'components/user/UserDestroy';
import PasswordManager from 'components/user/PasswordManager';
import UserInfo from 'components/user/UserInfo';
import PrivacyPolicy from 'components/user/PrivacyPolicy';
import Terms from 'components/user/Terms';

import ProjectList from 'components/ProjectList';
import ProjectForm from 'components/project/ProjectForm';
import ProjectInfo from 'components/ProjectInfo';
import GlobalProjects from 'components/GlobalProjects';
import Loads from 'components/project/Loads';
import SoilLog from 'components/project/soil-log/SoilLog';
import Pile from 'components/project/Pile';
import Results from 'components/project/Results';
import AllTabs from 'components/project/AllTabs';

function requireAuth(nextState, replace) {
  if (!UserStore.loggedIn()) {
    replace({
      pathname: '/login',
      state: { nextPathname: nextState.location.pathname }
    });
  }
}

export default class App extends React.Component {
  componentDidMount() {
    CapApp.addListener('appUrlOpen', ({url}) => this.navigateToDeepLink(url));
    this.checkForDeepLink();
    SplashScreen.hide();
    setTimeout(SplashScreen.hide, 15000);

    if (window.device.platform == 'ios' && UserStore.loggedIn()) {
      API.requestAppTrackingTransparency();
    }
  }

  checkForDeepLink = async () => {
    const {url} = await CapApp.getLaunchUrl() || {};
    this.navigateToDeepLink(url);
  }

  navigateToDeepLink = (url) => {
    if (url) {
      const path = url.split('#').pop() || url.replace('geoapp:', '');
      hashHistory.push(path);
    }
  }

  render() {
    return(
      <Router history={hashHistory}>
        <Route path="/" component={MainLayout}>
          <IndexRedirect to={ UserStore.loggedIn() ? "/projects" : "/login" } />
          <Route component={ UnauthenticatedLayout }>
            <Route path="/login" component={Login}/>
            <Route path="/logout" component={Logout}/>
            <Route path="/registration" component={Registration}/>
            <Route path="/passwordmanager" component={PasswordManager}/>
          </Route>

          <Route component={ GenericLayout }>
            <Route path="/userinfo" component={UserInfo}/>
            <Route path="/terms" component={Terms}/>
            <Route path="/privacy" component={PrivacyPolicy}/>
            <Route path="/projects-info" component={ProjectInfo}/>
            <Route path="/account" component={EditProfile}/>
            <Route path="/destroy_requests" component={UserDestroy}/>
          </Route>
          <Route component={ ProjectsLayout }>
            <Route path="/projects" component={ProjectList} onEnter={requireAuth} />
            <Route path="/global-projects" component={GlobalProjects} onEnter={requireAuth}/>
          </Route>
          <Route component={ NewProjectLayout }>
            <Route path="/projects/new" component={ProjectForm} onEnter={requireAuth}/>
          </Route>
          <Route component={ ProjectLayout }>
            <Route path="/projects/:id/" components={{main: ProjectForm}} onEnter={requireAuth} />
            <Route path="/projects/:id/settings" components={{main: ProjectForm}} onEnter={requireAuth} />
            <Route path="/projects/:id/copy" components={{main: ProjectForm}} onEnter={requireAuth} />
            <Route path="/projects/:id/loads" components={{main: Loads}} onEnter={requireAuth} />
            <Route path="/projects/:id/soil" components={{main: SoilLog}} onEnter={requireAuth} />
            <Route path="/projects/:id/pile" components={{main: Pile}} onEnter={requireAuth} />
            <Route path="/projects/:id/results" components={{main: Results}} onEnter={requireAuth} />
            <Route path="/projects/:id/all" components={{main: AllTabs}} onEnter={requireAuth} />
          </Route>
        </Route>
      </Router>
    );
  }
}
