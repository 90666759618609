import { pathify } from 'lib/svg-helpers';

export default ({OFFSET_X, OFFSET_Y, GRAPH_WIDTH, GRAPH_HEIGHT}) => {
  const ORIGIN = {x: OFFSET_X, y: OFFSET_Y}
  const xAxis = [ORIGIN, {x: (OFFSET_X + GRAPH_WIDTH), y: OFFSET_Y}];
  const yAxis = [ORIGIN, {x: OFFSET_X, y: (OFFSET_Y + GRAPH_HEIGHT)}];
  
  return (
    <g className="axies">
      <path id="x-axis" className="axis" d={pathify(xAxis)} />
      <path id="y-axis" className="axis" d={pathify(yAxis)} />
    </g>
  );
}
