export const CURRENT_SCHEMA_VERSION = 1;
export const MAX_LEVEL = 200; //feet
export const BLOW_COUNT_INTERVAL_E = 5; //feet
export const BLOW_COUNT_INTERVAL_M = 1.524; //meters
export const BLOW_COUNT_INTERVAL = {"english": BLOW_COUNT_INTERVAL_E, "metric": BLOW_COUNT_INTERVAL_M};
export const HELIX_CAP_COEF = 0.065;
export const SHAFT_CAP_COEF = 0.0019;
export const SHAFT_FRICTION_COEF = 2/3;
export const SHAFT_FRICTION_REDUCTION = 1/2;
export const LEAD_GROUT_STRENGTH_N70 = 28;

export default {};
