import { IonItemToggle, IonIcon } from 'reactionic';

import PileSelectionActions from 'actions/PileSelectionActions';

export default function DualCutterFilter({checked}) {
  const includeDualCutter = (checked) => {
    PileSelectionActions.setFilters({includeDualCutter: checked});
  };

  return (
    <IonItemToggle
      color="positive"
      label={<span className="clickable">Include Dual Cutter:</span>}
      checked={checked}
      handleChange={includeDualCutter}
    />
  );
}
