import project from 'reducers/project';
import migrate from 'reducers/project/migrations';

const matchId = (state, action, func) => {
  return state.map((p) => p.id != action.projectId ? p : func(p));
}
const hasOfflineKeys = (project) => (project.createdOfflineAt || project.updatedOfflineAt || project.deletedOfflineAt);

function upsertProject(state, action) {
  const normalProject = migrate(action.project);

  let foundProject = false;
  const projects = _.map(state, (project) => {
    if (project.id == action.tempId || project.id == action.project.id) {
      foundProject = project;
      return normalProject;
    }

    return project;
  });

  if (foundProject) return projects;
  return [normalProject, ...state];
}

export default function(state=[], action) {
  switch(action.type) {
    case 'RECEIVED_PROJECT_LIST':
      const offlineProjects = _.filter(state, hasOfflineKeys);
      return _.uniqBy([
        ...offlineProjects,
        ...action.projectList.map(migrate)
      ], 'id');
    case 'RECEIVED_PROJECT':
      return upsertProject(state, action);
    case 'UPDATE_PROJECT_PROPERTIES':
      return _.map(state, p => project(p, action));
    case 'DELETED_PROJECT':
      return _.reject(state, {id: action.project.id});
    case 'MARK_PROJECT_DELETED':
      return matchId(state, action, (project) => ({...project, deletedOfflineAt: new Date().toISOString()}));
    case 'LOGOUT':
    case 'RESET':
      return [];
    default:
      return state;
  }
}
