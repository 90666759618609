import { hashHistory } from 'react-router';
import { IonContent, IonNavBar } from 'reactionic';
import UserForm from 'components/user/UserForm';
import UserStore from 'stores/UserStore';
import {withOnline} from 'components/helpers/online-helper';

const goToProjects = () => hashHistory.push({pathname: '/projects'});

export default withOnline(function EditProfile({online}) {
  const user = UserStore.getInfo();

  if (!online) {
    return (
      <div>
        <IonNavBar customClasses="bar-dark" title="Edit Account"/>
        <IonContent customClasses="has-header">
          <div className="padding">
            <p className="error">You must be online to edit your account information.</p>
          </div>
        </IonContent>
      </div>
    );
  }

  return (
    <div>
      <IonNavBar customClasses="bar-dark" title="Edit Account"/>
      <IonContent customClasses="has-header">
        <UserForm user={user} actionLabel="Save" onSuccess={goToProjects}/>
      </IonContent>
    </div>
  );
});
