import { BLOW_COUNT_INTERVAL_E } from 'lib/constants';

export default function maxGraphDepth(project) {
  const {data: {pileSegments=[], blowCounts}} = project;
  const pileLength = _.sumBy(pileSegments, 'length') / 12;

  const lastBlowCountIndex = _.findLastIndex(blowCounts, (b) => b > 0);
  const blowCountDepth = lastBlowCountIndex * BLOW_COUNT_INTERVAL_E;

  return Math.max(20, pileLength, blowCountDepth) + 6;
}
