import { Link } from 'react-router';
import { IonContent, IonNavBar, IonButton } from 'reactionic';
import { userInfo } from 'config/terms-conditions';
import UserStore from 'stores/UserStore';

export default function UserInfo() {
  const backPath = UserStore.loggedIn() ? '/account' : '/registration';
  const BackButton = (
    <IonButton
      icon="ion-chevron-left"
      link={backPath}
      iconPosition="left"
      title="back"
      color="light"
      type="clear"
    >
      {UserStore.loggedIn() ? 'Account' : 'Registration'}
    </IonButton>
  );
  return (
    <div>
      <IonNavBar customClasses="bar-dark" leftButton={BackButton}/>
      <IonContent customClasses="has-header">
        <div className="padding">
          <h2>Information Policy</h2>
          <p>{userInfo}</p>
          <p>See <Link to="privacy">Privacy Policy</Link></p>
        </div>
      </IonContent>
    </div>
  );
}
