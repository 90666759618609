import { Link, hashHistory } from 'react-router';
import { IonSpinner } from 'reactionic';
import RegistrationActions from 'actions/RegistrationActions';
import UserActions from 'actions/UserActions';
import UserStore from 'stores/UserStore';
import FormRow from 'components/helpers/form-row';
import countryOptions from 'components/helpers/country-options';

const NEW_USER = {
  name: '',
  company: '',
  address: '',
  city: '',
  state: '',
  zipcode: '',
  country: '',
  phone: '',
  email: ''
};
const KEYS = Object.keys(NEW_USER);

export default class UserForm extends React.Component {
  state = {
    newUser: _.isEmpty(this.props.user),
    user: this.props.user ? _.pick(this.props.user, KEYS) : NEW_USER,
    password: '',
    passwordConfirmation: '',
    loading: false,
    errors: null,
  }

  componentDidMount() {
    this.unsubscribeUserStore = UserStore.subscribe(this._onChange);
  }

  componentWillUnmount() {
    this.unsubscribeUserStore();
  }

  _onChange = () => {
    const {userId, errors} = UserStore.getRegStatus();

    if (userId && !errors) {
      this.props.onSuccess();
    } else {
      this.setState({errors, loading: false});
    }
  }

  set = (field) => (e) => this.setState({[field]: e.target.value, error: null})
  setUser = (field) => (e) => {
    e.persist()
    this.setState(({user}) => ({user: {...user, [field]: e.target.value}, errors: null}));
  }

  goToTermsPage = () => hashHistory.push({pathname: '/terms'})

  validatePasswords = (errors) => {
    const {password, passwordConfirmation} = this.state;
    if (password !== passwordConfirmation) {
      errors.push('Passwords must match');
    }
  }

  isValid = () => {
    const errors = [];
    this.state.newUser && this.validatePasswords(errors);
    this.setState({errors: errors.join('; ') || null});
    return _.isEmpty(errors);
  }

  submit = (event) => {
    event.preventDefault();

    if (this.isValid()) {
      this.setState({loading: true, errors: null});
      const {newUser, user, password} = this.state;
      if (newUser) {
        RegistrationActions.newRegistration({...user, password});
      } else {
        UserActions.update(user);
      }
    }
  }

  render() {
    const {actionLabel} = this.props;
    const {newUser, user, password, passwordConfirmation, errors, loading} = this.state;
    return (
      <div className="registration-page">
        <form className="list" onSubmit={this.submit} disabled={loading}>
          <FormRow label="Name">
            <input type="text" value={user.name} onChange={this.setUser('name')}/>
          </FormRow>

          <FormRow label="Company">
            <input type="text" value={user.company} onChange={this.setUser('company')}/>
          </FormRow>

          <FormRow label="Address">
            <input type="text" value={user.address} onChange={this.setUser('address')}/>
          </FormRow>

          <FormRow label="City">
            <input type="text" value={user.city} onChange={this.setUser('city')}/>
          </FormRow>

          <FormRow label="State/Province">
            <input type="text" value={user.state} onChange={this.setUser('state')}/>
          </FormRow>

          <FormRow label="Postal Code">
            <input type="text" value={user.zipcode} onChange={this.setUser('zipcode')}/>
          </FormRow>

          <FormRow label="Country" className="item-select">
            <select value={user.country || 'US'} onChange={this.setUser('country')}>
              {countryOptions}
            </select>
          </FormRow>

          <FormRow label="Phone">
            <input type="text" value={user.phone} onChange={this.setUser('phone')}/>
          </FormRow>

          <FormRow label="Email">
            <input type="text" value={user.email} onChange={this.setUser('email')} autoComplete="username"/>
          </FormRow>

          {newUser && <FormRow label="Password">
            <input type="password" value={password} onChange={this.set('password')} autoComplete="new-password"/>
          </FormRow>}

          {newUser && <FormRow label="Password Confirmation">
            <input type="password" value={passwordConfirmation} onChange={this.set('passwordConfirmation')} autoComplete="new-password"/>
          </FormRow>}

          <br />
          <div className="padding">
            {errors && <p className="error">{errors}</p>}
            <button className="button button-block button-positive" type="submit" disabled={loading}>
              {loading && <IonSpinner icon="ios-small" />}
              {actionLabel}
            </button>
            <br />
            <br />
            <Link to="userinfo">Why does Magnum need this information?</Link>
            <br />
            <br />
            <Link to="privacy">Privacy Policy</Link>
            <br />
            <br />
            {!newUser &&
              <div>
                <Link className="button" to="destroy_requests">Delete Account</Link>
              </div>
            }
          </div>
        </form>
      </div>
    );
  }
}
